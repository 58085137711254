/* ————————————————————
  .rebound
——————————————————————— */
.rebound {
  margin-top: vr(2);
  padding-top: vr(2) !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;

  .title {
    color: #fff;
  }

  + .l_footer {
    margin-top: 0;
  }
}


/* ————————————————————
  .rebound__divider
——————————————————————— */
.rebound__divider {
  display: block;
  width: col(1, 1);
  height: 1px;
  background-color: #fff;
  opacity: 0.1;
  border: 0;
  margin-top: vr(2);
  margin-left: auto;
  margin-right: auto;
}