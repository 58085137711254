/* ————————————————————
  .tablist
  (wrapper tabs + sections)
——————————————————————— */
.tablist{
	border-bottom: 1px solid red;
}



/* ————————————————————
  tablist panel
——————————————————————— */
.tablist section[role="tabpanel"][aria-hidden="true"]{
	display: none;
}



/* ————————————————————
  .tablist__nav
——————————————————————— */
.tablist__nav{
	position: relative;

	/* MODIFIER
	————————————————— */
	&.-long{
		overflow: scroll;
		@extend %ellipsis;
		padding:0 vr(1);
		margin-left: vr(-1);
		max-width: calc( 100% + #{$gutters*2} );
		width: calc( 100% + #{$gutters*2} );

/*		&:after{
			content:"…";
			position: absolute;
			top:0;
			right:0;
			background-color: #fff;
			padding: 0 .25em;
		}*/
	 }


}


/* ————————————————————
  .tablist__nav tab
——————————————————————— */
.tablist__nav {

	[role="tab"] {
		margin-top: 0 !important;
		margin-right: -.27em;
		padding: .5em 1em;
		border: 1px solid #fff;
		border-bottom: 0;
	}

	[role="tab"][aria-selected="true"] {
		border-color: red;
	}

}