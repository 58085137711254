/* ————————————————————
  .grid-clients
——————————————————————— */
.grid-logos {
  margin-top: vr(2);
}


/* ————————————————————
  .grid-clients__item
——————————————————————— */
.grid-logos__item {
  position: relative;
}

.grid-logos__item__image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 85%;

  /* MEDIA QUERIES
  ————————————————— */
  @include breakpoint($breakpoint-desktop) {
    width: 65%;
  }
}

