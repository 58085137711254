
/* ————————————————————
  SETUP TYPOGRAPHY
——————————————————————— */
html {
  color: $text-color;
  @include bodyFontSize($body-font-size--mobile);


  /* MEDIA QUERIES
  ————————————————— */
  @include breakpoint($breakpoint-tablet) {
    @include bodyFontSize($body-font-size);
  }
}

body {
  font-family: $verdana;
  font-size: ms(0);
  line-height: $document-line-height;
  font-weight: 400;
  font-style: normal;
  font-smooth: auto;
  font-smoothing: subpixel-antialiased; // subpixel-antialiased
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  hyphens: auto;
  word-wrap: break-word; // 'break-word'   Unbreakable words may be broken at arbitrary points.

  // debug line height
  // set it on body and not on html
  //@include show-baseline-grid();


  //add custom fonts when loaded
  .fonts-loaded & {
    font-family: 'tabacG4', $helvetica;
  }
}




/* ————————————————————
  selection colors
——————————————————————— */
::selection {
  background: $selection-background-color;
  color: $selection-text-color;
  text-shadow: none;
}

img::selection {  background: transparent;
}

body {  -webkit-tap-highlight-color: transparent;
}



/**
 * Links
 */

a {
  //color: inherit;
  text-decoration: none;
  background-color: transparent; //Remove the gray background color from active links in IE 10.

  p & {
    text-decoration: none;
    border-bottom: 1px solid $text-color;
    transition: all 0.3s ease-out;
    // text-decoration: none;

    .no-touchevents &:hover {
      text-decoration: none;
      color: $text-lien-color;
      // color: lighten($text-lien-color, 20%);
      border-bottom-color: $text-lien-color;
    }
  }

  &:focus {
    outline: thin dotted;
  }

  &:hover,
  &:active {
    //outline: 0; //Improve readability when focused and also mouse hovered in all browsers.
  }

  .no-touchevents &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }
}




/**
 * Titres
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: normal;
  line-height: inherit;
}

.title {
  text-transform: uppercase;
  font-size: ms(6);
  line-height: vr(2);
  color: $text-black;

  .fonts-loaded & {
    font-family: 'vevey', $helvetica;
  }

  &.-large {
    font-size: ms(7);
    line-height: vr(2);
  }

  &.-larger {
    font-size: ms(6);
    line-height: vr(2.5);

    /* MEDIA QUERIES
    ————————————————— */
    @include breakpoint($breakpoint-tablet) {
      font-size: ms(9);
      line-height: vr(3.5);
    }
  }

  &.-small {
    font-size: ms(5);
    line-height: vr(2);
  }
}


/**
 * paragraph
 */

.p {
  &.-large {
    font-size: ms(1);
    line-height: vr(1.2);
    color: $text-black;
  }

  &.-small {
    font-size: ms(-1);
  }

  &.-smaller {
    font-size: ms(-2);
  }
}

.tc_text-grey {
  color: $text-grey;
}

.tc_text-light {
  color: $text-light;
}

.tc_text-white {
  color: #fff;
}

// p{
//   a{
//     // display: inline-block;
//     text-decoration: underline;
//     // text-decoration: none;
//     // border-bottom: 1px solid $linkcolor;


//     .no-touchevents &:hover {
//       color: $bleu;
//     }
//   }
// }


/**
 * em
 */
em {
  font-style: inherit;
}



/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */

b,
strong {
  font-weight: 600;
}




/**
 * highlight
 */

// .highlight{
//   background-color: $jaune-light;
//   color: $textcolor;
// }





/**
 * Code
 */
// code,
// pre{
//   // border: 1px solid red;
//   font-family: $monospace;
//   font-size: ms(-1);
//   line-height: inherit;
//   padding: vr(2);
//   display: block;
//   background-color: $noir-5; 
// }




.t_raw {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "tabacG1";
    font-weight: 700;
    color: $noir;
    font-size: ms(1);
  }

  p + ,
  ul +  {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: vr(1.5);
      @include breakpoint($breakpoint-tablet) {
        margin-top: vr(3);
      }
    }
  }
}

