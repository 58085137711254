/* ————————————————————
  .testimonial
——————————————————————— */
.testimonial {
  color: #fff;
  padding: vr(4) vr(2) !important;

  /* MEDIA QUERIES
   ————————————————— */
  @include breakpoint($breakpoint-tablet-large) {
    padding-left: col(3, 8) !important;
    padding-bottom: vr(4) !important;
    padding-top: vr(5) !important;
  }
}



/* ————————————————————
  .testimonial__chapo
——————————————————————— */
.testimonial__chapo {
  font-size: ms(2);
  line-height: vr(1.4);
  font-family: "tabacG1";
  font-weight: 700;
  display: block;
  color: #fff;
  position: relative;
  z-index: 2;

  .icon {
    position: absolute;
    z-index: 0;
    top: vr(-1.5);
    left: vr(-1.5);
    width: vr(3);
    height: vr(3);
    opacity: 0.1;
  }

  /* MEDIA QUERIES
   ————————————————— */
  @include breakpoint($breakpoint-tablet) {
    font-size: ms(2);
    line-height: vr(1.5);

    .icon {
      top: vr(-2);
      left: vr(-2);
      width: vr(4);
      height: vr(4);
    }
  }
}


/* ————————————————————
  .testimonial__body
——————————————————————— */
.testimonial__body {
}


/* ————————————————————
  .testimonial__author
——————————————————————— */
.testimonial__author {
  margin-top: vr(2);
  display: block;
  font-style: normal;

  &::before {
    content: '— ';
  }
}