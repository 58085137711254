
/* ————————————————————
  STATE UTILITIES CLASSES
——————————————————————— */
.is {
  &--visible {    display: block;
  }

  &--hidden {    display: none !important;
  }
}


/*
.u_message{
  padding: vr(1);
  border: 1px solid $noir-20;

  &--warning{
    // border: 1px solid lighten($rouge, 10%);
    // color: $rouge;
  }
}
*/



/* ————————————————————
  LAYOUT UTILITIES CLASSES
——————————————————————— */
.l_ {
  &col-1 {
    @include col(1, 1);
  }

  &col-2 {
    @include col(1, 2);
  }

  &col-3 {
    @include col(1, 3);
  }

  &col-4 {
    @include col(1, 4);
  }

  &col-5 {
    @include col(1, 5);
  }

  &col-6 {
    @include col(1, 6);
  }


  // display
  &db {    display: block;
  }

  &di {    display: inline-block;
  }

  &dib {    display: inline-block;
  }


  // clear
  &cf {
    @include clearfix();
  }

  &cb {    clear: both;
  }

  &cl {    clear: left;
  }

  &cr {    clear: right;
  }

  // float
  &fl {    float: left;
  }

  &fr {    float: right;
  }

  &fn {    float: none;
  }


  //margins
  &ml-auto {    margin-left: auto;
  }

  &mr-auto {    margin-right: auto;
  }

  &mx-auto {    margin-left: auto;    margin-right: auto;
  }

  // adjust margins top
  &mt0 {    margin-top: 0;
  }

  &mt05 {    margin-top: vr(0.5);
  }

  &mt1 {    margin-top: vr(1);
  }

  &mt2 {    margin-top: vr(2);
  }

  &mt3 {    margin-top: vr(3);
  }

  &mt4 {    margin-top: vr(4);
  }

  // adjust margins right
  &mr1 {    margin-right: vr(1);
  }

  &mr2 {    margin-right: vr(2);
  }

  &mr3 {    margin-right: vr(3);
  }


  // adjust margins bottom
  &mb1 {    margin-bottom: vr(1);
  }

  &mb2 {    margin-bottom: vr(2);
  }

  &mb3 {    margin-bottom: vr(3);
  }


  // adjust margins left
  &ml1 {    margin-left: vr(1);
  }

  &ml2 {    margin-left: vr(2);
  }

  &ml3 {    margin-left: vr(3);
  }



  // adjust padding top
  &pt1 {    padding-top: vr(1);
  }

  &pt2 {    padding-top: vr(2);
  }

  &pt3 {    padding-top: vr(3);
  }

  &pt4 {    padding-top: vr(4);
  }

  // adjust padding bottom
  &pb1 {    padding-bottom: vr(1);
  }

  &pb2 {    padding-bottom: vr(2);
  }

  &pb3 {    padding-bottom: vr(3);
  }

  &pb4 {    padding-bottom: vr(4);
  }
}

.u_float {
  //float
  &--right {    float: right;
  }

  &--left {    float: left;
  }
}



/* ————————————————————
  TEXT UTILITIES CLASSES
——————————————————————— */
.t_ {
  //font family
  &sans {    font-family: sans-serif;
  }

  &serif {    font-family: serif;
  }

  //cesure
  &nowrap {
    @extend %dontBreakWords;
  }

  &oneline {
    @extend %ellipsis;
    max-width: 100%;
    display: inline-block;
  }

  // align
  &right {    text-align: right;
  }

  &left {    text-align: left;
  }

  &center {    text-align: center;
  }

  &justify {    text-align: justify;
  }

  // transform
  &lowercase {    text-transform: lowercase;
  }

  &uppercase {    text-transform: uppercase;
  }

  // font size
  &ms-2 {    font-size: ms(-2);
  }

  &ms-1 {    font-size: ms(-1);
  }

  &ms0 {    font-size: ms(0);
  }

  &ms1 {    font-size: ms(1);
  }

  &ms2 {    font-size: ms(2);
  }

  &ms3 {    font-size: ms(3);
  }

  &ms4 {    font-size: ms(4);
  }

  &ms5 {    font-size: ms(5);
  }

  // weight
  &light {    font-weight: 300;
  }

  &bold {    font-weight: 600;
  }

  &reset-weight {    font-weight: 400;
  }

  // style
  &italic {    font-style: italic;
  }

  &reset-style {    font-style: normal;
  }

  // colors
  &noir80 {    color: $noir-80;
  }

  &noir60 {    color: $noir-60;
  }

  &noir40 {    color: $noir-40;
  }

  &noir20 {    color: $noir-20;
  }

  // decoration
  &reset-decoration {    text-decoration: none;
  }

  &underline {    text-decoration: underline;
  }

  &linetrough {    text-decoration: line-through;
  }

  //&--ls-1{letter-spacing: -.05em;}

  // hidden
  &hide {
    @extend %hide-text;
  }



  //exposant
  /*  &--exposant{
    font-size: .6em;
  }*/
}

