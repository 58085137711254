/* ————————————————————
  .cta-reference
——————————————————————— */
.cta-reference {
  position: relative;
  display: block;
  // height: 0;
  // padding-bottom: 60%;
  padding-top: vr(3);
  padding-bottom: vr(1);
  margin-left: auto;
  margin-right: auto;
  flex-direction: column-reverse;
  overflow: hidden;
  background-color: $noir;

  /* MEDIA QUERIES
  ————————————————— */
  // @include breakpoint($breakpoint-tablet) {
  //   padding-bottom: 50%;
  // }
  // @include breakpoint($breakpoint-desktop) {
  //   padding-bottom: 35%;
  // }


  /* HOVER
  ————————————————— */
  .no-touchevents &:hover {
    .cta-reference__image {
      opacity: 0.5;
      transform: scale(1.01);
    }
  }

  .no-touchevents  &:hover {
    &.-nohover {
      .cta-reference__image {
        opacity: 1;
        transform: none;
      }
    }
  }
}


/* ————————————————————
  .cta-reference__image
——————————————————————— */
.cta-reference__image {
  @include cover();
  z-index: 1;
  opacity: 1;
  transform-origin: center center;
  will-change: transform;
  transition: opacity 1.2s ease, transform 1.2s ease;
}


/* ————————————————————
  .cta-reference__content
——————————————————————— */
.cta-reference__content {
  z-index: 2;
  color: #fff;
  margin-left: auto;
  min-height: vr(19);
  padding: vr(1);

  .title {
    color: inherit;
  }

  .p {
    opacity: 0.6;
  }


  /* MEDIA QUERIES
  ————————————————— */
  @include breakpoint($breakpoint-tablet-large) {
    padding: vr(0);
    min-height: vr(15);
  }
}




/* ————————————————————
  .cta-reference__link
——————————————————————— */
.cta-reference__link {
  @include cover();
  z-index: 3;

  /* HOVER
  ————————————————— */
  .no-touchevents &:hover {
    text-decoration: none;
  }
}