/* ————————————————————
  .l_footer
——————————————————————— */
.l_footer {
  color: $text-light;
  font-size: ms(-1);
  line-height: 1.6;
  padding-top: vr(2);
  padding-bottom: vr(1);
  margin-top: vr(3);
}


/* ————————————————————
  .l_footer__logo
——————————————————————— */
.l_footer__logo {
  display: block;
  width: vr(7);
  height: vr(2);
  transition: opacity 0.3s $easeOutQuad;

  .no-touchevents &:hover {
    opacity: 0.8;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.l_footer > .l_grid {
  align-items: center;
  margin-top: vr(2);
}









/* ————————————————————
  .l_footer__columns
——————————————————————— */
.l_footer__columns {
  display: flex;
  flex-wrap: wrap;
  margin-top: vr(2);
}


/* ————————————————————
  .l_footer__column
——————————————————————— */
.l_footer__column {
  width: col(1, 1);
  margin-left: vr(1);
  margin-right: vr(1);
  flex-shrink: 0;



  /* MODIFIER
  ————————————————— */
  @include breakpoint($breakpoint-tablet) {
    margin-top: vr(0);
    width: col(1, 3);
  }
  @include breakpoint($breakpoint-desktop) {
    width: col(1, 4);
  }
}


/* ————————————————————
  .l_footer__copyright
——————————————————————— */
.l_footer__copyright {
  width: col(1, 1);
  margin-left: vr(1);
  margin-right: vr(1);
  color: $text-light;
  font-size: ms(-2);
  margin-top: vr(2);

  p {
    display: inline-block;
    border: 1px solid red;

    &:first-child {
      margin-right: auto;
      margin-top: 0;
    }
  }
}


/* ————————————————————
  .l_footer__language
——————————————————————— */
.l_footer__language {
  display: block;
  // position: absolute;
  // top: vr(3);
  // right: vr(1);
  margin: 0;

  a {
    text-decoration: none;
    border: 0;


    /* HOVER
    ————————————————— */
    .no-touchevents &:hover {
      opacity: 0.8;
    }
  }
  @include breakpoint($breakpoint-tablet-large) {
    text-align: right;
  }
}

