/* ————————————————————
  TABLES SPECIFIC RESET
——————————————————————— */
/**
 * @section Tables
 * Styling for tables
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 100%;
  width: 100%;
}

th,
td {
  text-align: left;
  padding: vr(.5) vr(1);
}

th {
  // background-color: lighten( $color-gray-light, 5% );
  // border-bottom: 1px solid $noir-10;
  font-weight: 600;
  vertical-align: bottom;
  font-size:ms(-1);
}

td {
  // border-top: 1px solid $noir-10;
  vertical-align: top;
}

/**
 * Adds zebra striping
 */
.table.-striped tbody tr:nth-child(odd) {
  background-color: $noir-20;
}





/* ————————————————————
  .table.-responsive
  —
  Pure CSS responsive tables
  Adds label to each cell using the [data-label] attribute
  @link https://techblog.livingsocial.com/blog/2015/04/06/responsive-tables-in-pure-css/
——————————————————————— */
.table.-responsive {
  thead {
    display: none;
    visibility: hidden;
  }

  tr {
    display: block;
  }

  td {
    border: 0;
    display: block;

    &:before {
      content: attr(data-label);
      display: block;
      font-weight: 600;
    }
  }


  /* MEDIA QUERIES
  ————————————————— */
  @include breakpoint($breakpoint-tablet){
    thead {
      display: table-header-group;
      visibility: visible;
    }

    tr {
      display: table-row;
    }

    td{
      display: table-cell;

      &:before {
        content: none;
      }
    }

  }

}
