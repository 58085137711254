/* ————————————————————
  .grid-services
——————————————————————— */
.grid-services {
}

/* ————————————————————
  .grid-services__item
—————————————————————— */
.grid-services__item {
  position: relative;
  margin-bottom: vr(1);
  overflow: hidden;
  background-color: $noir;

  /* HOVER
  ————————————————— */
  .no-touchevents &:hover {
    .grid-services__image {
      opacity: 0.5;
      transform: scale(1.01);
    }
  }
}

/* ————————————————————
  .grid-services__image
——————————————————————— */
.grid-services__image {
  display: block;
  height: 0;
  padding-bottom: 50%;
  z-index: 1;
  opacity: 0.8;
  transition: opacity 1.2s ease, transform 1.2s ease;
}


/* ————————————————————
  .grid-services__link
——————————————————————— */
.grid-services__link {
  @include cover();
  z-index: 2;
  margin: 0;
  background-color: transparent;
  font-size: ms(-1);
  color: #fff;
  padding: vr(6) vr(1) vr(1) vr(1);
  text-decoration: none;

  /* HOVER
  ————————————————— */
  .no-touchevents &:hover {
    text-decoration: none;
  }
}


/* ————————————————————
  .grid-services__link__title
——————————————————————— */
.grid-services__link__title {
  font-weight: 700;
  font-family: tabacG1;
  font-size: ms(1);
}


/* ————————————————————
  .grid-services__link__roles
——————————————————————— */
.grid-services__link__roles {
  opacity: 0.8;
}