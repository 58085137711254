/* ————————————————————
  FORM SPECIFIC RESET
——————————————————————— */

html input[disabled] {
  cursor: default; //Re-set default cursor for disabled elements.
}

select {
  text-transform: none; //Address inconsistent `text-transform` inheritance for `button` and `select`.
}


input {
  line-height: normal; // Address Firefox 4+ setting `line-height` on `input` using `!important` in the UA stylesheet.

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &::-ms-clear {
    display: none;
  }

  &[type="checkbox"],
  &[type="radio"] {
    padding: 0; // Remove excess padding in IE 8/9/10.
  }

  /**
  * Fix the cursor style for Chrome's increment/decrement buttons. For certain
  * `font-size` values of the `input`, it causes the cursor style of the
  * decrement button to change from `default` to `text`.
   */
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  /**
  * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
  * 2. Address `box-sizing` set to `border-box` in Safari and Chrome
  *    (include `-moz` to future-proof).
  */
  &[type="search"] {
    -webkit-appearance: textfield; /* 1 */
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box; /* 2 */
    box-sizing: content-box;
  }


  /**
  * Remove inner padding and search cancel button in Safari and Chrome on OS X.
  * Safari (but not Chrome) clips the cancel button when the search input has
  * padding (and `textfield` appearance).
  */

  &[type="search"]::-webkit-search-cancel-button,
  &[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}







  /* ————————————————————
    fieldset
  ——————————————————————— */
  fieldset{
  }


  /* ————————————————————
    legend
  ——————————————————————— */
  legend{
  }


  /* ————————————————————
    label
  ——————————————————————— */
  label,
  .form-field__error{
    display: block;
    font-size: ms(-1);
  }


  /* ————————————————————
    form-field
    (wrapper form label + input + error message)
  ——————————————————————— */
  .form-field{
    border: 1px solid red;
    position: relative;

    &--group{
      display: inline-block;
      border: 1px solid red;
      margin-right: 1em;
      margin-top: 0 !important;

      label{
        display: inline-block;
      }
    }
  }


  /* ————————————————————
    form-field__error
  ——————————————————————— */
  .form-field__error{
    display: none;
    color: $active-color;
    margin-top: 0 !important;
    color: #fff;
    background-color: red;
    padding: 0 vr(.5);


    /* MODIFIER
    ————————————————— */
    .has-error &{
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
    }
  }



  /* ————————————————————
    inputs
  ——————————————————————— */
  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="url"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="week"],
  input[type="number"],
  input[type="search"],
  input[type="tel"],
  input[type="color"],
  select,textarea{
    border: 0;
    margin: 0 !important;
    display: inline-block;
    vertical-align: middle;

    width: 100%;
    background-color: $form-background-color;
    color: $text-color;
    text-indent: .5em;
    appearance: none;
    transition : all 0.3s ease-out;

    /* THEME
    ————————————————— */
    height: vr(2);



    /* focus */
    &:focus {
      outline: none !important;
      outline: 0;
      background-color: lighten($form-background-color, 10%);
    }

    /* disabled */
    &[disabled] {
      opacity: .4;
      // background-color: lighten($form-background-color, 10%);
      // color: $grey;
      cursor: not-allowed;
    }


  }



  /* ————————————————————
    textarea
  ——————————————————————— */
  textarea{
    height: auto;
    text-indent: 0;
    padding: .5em;
    resize: none;
    overflow: auto; //Remove default vertical scrollbar in IE 8/9/10/11.
  }



  /* ————————————————————
    placeholder
  ——————————————————————— */
  // input,
  // textarea{
  //   &:-moz-placeholder {
  //     color: lighten($noir, 40%);
  //     font-weight: normal !important;

  //   }
  //   &:-ms-input-placeholder {
  //     color: lighten($noir, 40%);
  //     font-weight: normal !important;

  //   }
  //   &::-webkit-input-placeholder {
  //     color: lighten($noir, 40%);
  //     font-weight: normal !important;
  //     padding-top: .1em;
  //   }
  // }




  /* ————————————————————
    select
  ——————————————————————— */
  select{
    text-indent: 0;

    /* HOVER
    ————————————————— */
    .no-touchevents &:hover {
      cursor: pointer;
    }
  }

  optgroup {
    font-style: normal;
    font-weight: 400;
    border: 1px solid red;
  }




  /* ————————————————————
    radio & checkboxes
  ——————————————————————— */
  input[type="radio"],
  input[type="checkbox"] {
    vertical-align: middle;
    margin-right: 1em;


    /* HOVER
    ————————————————— */
    & + label{
      font-size: ms(0);

      .no-touchevents &:hover {
        cursor: pointer;
      }
    }

    .no-touchevents &:hover {
      cursor: pointer;
    }
  }

  input[type="file"]:focus,
  input[type="radio"]:focus,
  input[type="checkbox"]:focus {
    outline: 1px thin $noir;
  }


