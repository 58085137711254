[data-lazy-bg]{
	opacity:0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;


	/* MODIFIER
	————————————————— */
	&.is-loaded{
		opacity:1;
	}

	&.fade-in{
		transition: opacity .8s $easeOutQuad 0s;
		.no-touchevents &:hover {transition: opacity .5s $easeOutQuad 0s;}
	}

}

