/* ————————————————————
  .l_header.-reference
——————————————————————— */
.l_header.-reference .l_container {
  padding-top: vr(8);
  padding-bottom: vr(2);
  text-align-last: left;
  position: relative;


  /* MEDIA QUERIES
  ————————————————— */
  @include breakpoint($breakpoint-tablet) {
    padding-top: vr(10);
    padding-bottom: vr(7);
  }

  .l_grid__item {
    margin-left: auto;
    position: relative;
    z-index: 2;
  }
}

.l_header__title-project,
.l_header__intro {
  position: relative;
  z-index: 2;
  @extend %transition-fadeinup;
}

.l_header.-reference .l_header__sup {
  color: #fff;
  opacity: 0.8;
}

.l_header.-reference .l_header__image {
  width: 100%;
  z-index: 1;
  opacity: 0.4;

  /* MEDIA QUERIES
  ————————————————— */
  @include breakpoint($breakpoint-tablet) {
    width: 60%;
    opacity: 0.6;
  }
}


/* ————————————————————
  .l_header__title-project
——————————————————————— */
.l_header__title-project {
  color: #fff;
  text-align: left;
}

/* ————————————————————
  .l_header__intro
——————————————————————— */
.l_header__intro {
  margin-top: vr(2);
  text-align: left;
  font-size: ms(1);
  line-height: vr(1.3);
  opacity: 0.6 !important;
  color: $noir-20;
  margin-top: vr(0.5);

  /* MEDIA QUERIES
  ————————————————— */
  @include breakpoint($breakpoint-tablet) {
    margin-top: vr(2);
  }
}




