/* ————————————————————
  GLOBAL SETUP
  margins & cascade
——————————————————————— */
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}

* + * {
  margin-top: vr(1);
}

body * {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

body,
img,
br,
li,
dt,
dd,
th,
td,
option,
label,
*:first-child {
  margin-top: 0;
}


/* html & body
  ————————————————— */
html {
  overflow-x: hidden !important;
  overflow-y: scroll; // All browsers without overlaying scrollbars
  -ms-overflow-style: -ms-autohiding-scrollbar; // IE11+
  background-color: $html-background-color;
}

body {
  overflow: hidden !important;
  position: relative;
  min-height: 100vh;
  background-color: $body-background-color;
}




/* ————————————————————
  MEDIA QUERIES FOR JS
——————————————————————— */
.indicator {
  position: absolute;
  top: -999em;
  left: -999em;

  &:after {
    content: "phone";
    @include breakpoint($breakpoint-tablet) {
      content: "tablet";
    }
    @include breakpoint($breakpoint-tablet-large) {
      content: "tablet-large";
    }
    @include breakpoint($breakpoint-desktop) {
      content: "desktop";
    }
    @include breakpoint($breakpoint-desktop-large) {
      content: "desktop-large";
    }
  }
}





/* ————————————————————
  BASE LAYOUT ELEMENTS
  .l_band
——————————————————————— */
.l_band {
  position: relative;
  display: block;
  @include clearfix();
  width: 100%;
  clear: both;
  padding: 0;


  /* MODIFIER COLORS
  ————————————————— */
  &.-noir5 {    background-color: $noir-5 !important;
  }

  &.-noir10 {    background-color: $noir-10;
  }

  &.-noir20 {    background-color: $noir-20;
  }

  &.-noir40 {    background-color: $noir-40;
  }

  &.-noir60 {    background-color: $noir-60;
  }

  &.-noir80 {    background-color: $noir-80;    color: #fff;
  }


  /* MODIFIER SIZE
  ————————————————— */
  &.-slim {
    padding: vr(1.5) 0;
  }

  &.-medium {
    padding: vr(2.5) 0;
  }

  &.-large {
    padding: vr(3.5) 0;
  }
}



/* ————————————————————
  .l_container
——————————————————————— */
.l_container {
  @include container();


  /*  MODIFIERS
   * ==================== */
  &.-gutters {
    margin-right: $gutters;
    margin-left: $gutters;

    /* MEDIA QUERIES
    ————————————————— */
    @include breakpoint($breakpoint-container) {
      margin-left: auto;
      margin-right: auto;
      padding-right: $gutters;
      padding-left: $gutters;
    }
  }

  &.-text {
    margin-right: $gutters;
    margin-left: $gutters;
    @include breakpoint($breakpoint-tablet-large) {
      max-width: col(3, 5);
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.-black {
    background-color: $noir;
    color: $text-light;
    padding-top: vr(1);
    padding-bottom: vr(1);

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 100%;
      background-color: $noir;
      z-index: 0;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 100%;
      background-color: $noir;
      z-index: 0;
    }
  }
}




/* ————————————————————
  .l_grid 
——————————————————————— */
.l_grid {
  display: flex;
  flex-wrap: wrap;

  &.-reverse {
    flex-direction: reverse;
    border: 1px solid red;
  }
}

.l_grid__item {
  position: relative;
  margin-left: vr(1);
  margin-right: vr(1);
  width: 100%;
}



/* ————————————————————
  .l_2col
——————————————————————— */
.l_grid.-size2 {
  .l_grid__item {

    /* MEDIA QUERIES
    ————————————————— */
    @include breakpoint($breakpoint-tablet-large) {
      width: col(1, 2);
      margin-top: 0;
    }
  }
}


/* ————————————————————
  .l_4col
——————————————————————— */
.l_grid.-size4 {
  .l_grid__item {
    width: col(1, 2);
    margin-top: vr(1);

    &.-square {
      height: 0;
      padding-bottom: col(1, 2);
    }

    /* MEDIA QUERIES
    ————————————————— */
    @include breakpoint($breakpoint-tablet) {
      width: col(1, 4);
      margin-top: 0;

      &.-square {
        height: 0;
        padding-bottom: col(1, 4);
      }
    }
  }
}



/* ————————————————————
  .df_section
——————————————————————— */
.df_section {
  margin-top: vr(2);
  margin-bottom: vr(1);

  /* MEDIA QUERIES
  ————————————————— */
  @include breakpoint($breakpoint-tablet) {
    margin-top: vr(4);
    margin-bottom: vr(2);
  }
}