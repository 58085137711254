/* ————————————————————
  LIBRARIES
——————————————————————— */
@import
	"modularscale-sass/stylesheets/_modularscale",
	"breakpoint-sass/stylesheets/_breakpoint";


/* ————————————————————
  SETUP
——————————————————————— */
@import
	"00-setup/_mixin",
	"00-setup/_variables";
	


/* ————————————————————
  BASE
——————————————————————— */
@import
	"01-base/__layout",
	"01-base/__typography",
	"01-base/_animation",
	"01-base/_buttons",
	"01-base/_forms",
	"01-base/_icons",
	"01-base/_lists",
	"01-base/_tables";



/* ————————————————————
  COMPONENTS
——————————————————————— */
@import
    "02-components/_images",
    "02-components/_tablist",
    "02-components/_lazy-bg";



/* ————————————————————
  MODULES
——————————————————————— */
@import "03-modules/_l_footer.scss";
@import "03-modules/_l_header-reference.scss";
@import "03-modules/_l_header.scss";
@import "03-modules/_m_outdated.scss";
@import "03-modules/accordion.scss";
@import "03-modules/cta-reference.scss";
@import "03-modules/grid-images.scss";
@import "03-modules/grid-logos.scss";
@import "03-modules/grid-profile.scss";
@import "03-modules/grid-push.scss";
@import "03-modules/grid-references.scss";
@import "03-modules/grid-services.scss";
@import "03-modules/nav-mobile.scss";
@import "03-modules/nav.scss";
@import "03-modules/rebound.scss";
@import "03-modules/socials.scss";
@import "03-modules/testimonial.scss";




/* ————————————————————
  UTILITIES  (need to overwrite cascade)
——————————————————————— */
@import "01-base/_utilities";



/* ————————————————————
  PAGE SPECIFIC
——————————————————————— */
/*@import
	"05-pages/_produit";*/
