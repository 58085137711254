/* ————————————————————
  FONT STACKS
——————————————————————— */
$sans: 'Futura', 'Trebuchet MS', 'Arial', sans-serif;
$helvetica: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$monospace: 'Ubuntu Mono', 'Consolas', 'Monaco', 'Lucida Console', 'Courier New', Courier, monospace;
$serif: 'Hoefler Text', 'Baskerville Old Face', 'Garamond', 'Times New Roman', serif;
$system: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Oxygen-Sans", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
$verdana: Verdana, Geneva, sans-serif;

/* ————————————————————
  FONTS DECLARATIONS
——————————————————————— */
$fontsPath: '/assets/fonts/';
@include font-face("tabacG1", tabacglamg1-semibold-webfont, 700, normal);
@include font-face("tabacG4", tabacglamg4-regular-webfont, 400, normal);
@include font-face("vevey", vevey-webfont, 400, normal);



/* ————————————————————
  COLORS
——————————————————————— */

/* Project colors
————————————————— */
$jaune: #ffd600;
$jaune-light: tint($jaune, 75%);
$jaune-lighter: tint($jaune, 90%);
$bleu: #002638;
$bleu-desature: tint($bleu, 40%);
$orange: #ff4a00;
$rouge: #fa3838;



/* Generic colors
————————————————— */
$blanc: #fff;
$noir: #000;
$noir-80: lighten($noir, 20%);
$noir-60: lighten($noir, 40%);
$noir-40: lighten($noir, 60%);
$noir-20: lighten($noir, 80%);
$noir-10: lighten($noir, 90%);
$noir-5: lighten($noir, 95%);
$text-black: #272727;
$text-grey: #636363;
$text-light: #a8a8a8;




/* Social media colors
————————————————— */
$twitter: #00aced;
$dribbble: #ea4c89;
$facebook: #3c5a98;
$pinterest: #cb2027;
$google: #cd4123;
$youtube: #cd332d;
$flickr: #ff0084;
$vimeo: #1ab7ea;
$rss: #fa9c39;
$dailymotion: #004f73;



/* Layout colors
————————————————— */
$html-background-color: $noir;
$body-background-color: #fff;


/* Text colors
————————————————— */
//body text
$text-color: $text-grey;
$text-lien-color: $text-grey;

//text selection
$selection-background-color: $noir;
$selection-text-color: $blanc;
$active-color: red;
$primaryBtn-background-color: $noir;
$primaryBtn-text-color: $blanc;
$secondaryBtn-background-color: $noir-40;
$secondaryBtn-text-color: $blanc;
$form-background-color: $noir-5;





/* ————————————————————
  MODULAR SCALE & VERTICAL RHYTHM SETUP
——————————————————————— */
$ms-base: 1rem; //set modular scale base 1rem = $body-font-size
$ms-ratio: 1.25; //set ratio for modular scale // $major-second $augmented-fourth
$body-font-size: 19px; //set body font size in px
$body-font-size--mobile: 15px;
$line-height-ratio: 1.5;
$document-font-size: ms(0);
$typeFactor: strip-units($body-font-size) / 16; //factor relative to default browser font size (16px)
$document-line-height: $document-font-size * $line-height-ratio; //$body-font-size * retio de baseline





/* ————————————————————
  CUSTOM GRID SETUP
——————————————————————— */
$columns: 6;
$column-width: vr(5.5);
$gutters: vr(1);
$containerWidth: ($columns * $column-width) + ($columns * $gutters * 2);
$containerWidthWithoutGutters: ($columns * $column-width) + ($columns * $gutters * 2) - ($gutters * 2);





/* ————————————————————
  BREAKPOINTS
——————————————————————— */
/*
$breakpoint-phone : min-width (480/strip-units($body-font-size))+0em; //480px
$breakpoint-tablet-small : min-width (600/strip-units($body-font-size))+0em;  //600px
$breakpoint-tablet : min-width (800/strip-units($body-font-size))+0em;
$breakpoint-desktop : min-width (1100/strip-units($body-font-size))+0em;
$breakpoint-desktop-large : min-width (1400/strip-units($body-font-size))+0em;
$breakpoint-container : min-width ( strip-units( $containerWidth ) * $typeFactor ) +2em;
$breakpoint-container--margins : min-width ( strip-units( $containerWidth ) * $typeFactor ) + 10em;
*/
$breakpoint-phone-only: max-width (599 / 16) + 0em;
$breakpoint-tablet: min-width (600 / 16) + 0em;
$breakpoint-tablet-large: min-width (900 / 16) + 0em;
$breakpoint-desktop: min-width (1200 / 16) + 0em;
$breakpoint-desktop-large: min-width (1800 / 16) + 0em;
$breakpoint-container: min-width ( strip-units( $containerWidth ) * $typeFactor ) +2em;
$breakpoint-container--margins: min-width ( strip-units( $containerWidth ) * $typeFactor ) + 10em;





/* ————————————————————
  CUSTOM GRID MIXINS
——————————————————————— */
@mixin container() {
  //@include clearfix();
  max-width: $containerWidth;
  position: relative;

  /* MEDIA QUERIES
	————————————————— */
  @include breakpoint($breakpoint-container) {
    margin-left: auto;
    margin-right: auto;
  }
}


/* COLUMNS COLS
————————————————— */
@mixin col($columns, $totalColumns) {
  float: left;
  margin-left: ($gutters);
  margin-right: ($gutters);
  width: calc(#{$columns*100%/$totalColumns} - #{$gutters*2});
}
@function col($columns, $totalColumns) {
  @return calc( #{$columns * 100% / $totalColumns} - #{$gutters * 2} );
}

/* COLUMNS SPLIT
————————————————— */
@mixin split($columns, $totalColumns) {
  float: left;
  width: #{$columns*100% / $totalColumns};
}
@function split($columns, $totalColumns) {
  @return calc( #{$columns * 100% / $totalColumns} );
}




/* ————————————————————
  Z_INDEX SETUP
——————————————————————— */
$z-index: (
  mobile-nav: 15, main-nav: 14, main-nav__logo: 12, main-nav-mobile: 10, nav-primary: 9, nav-primary__listitem__dropdown: 8
);




/* ————————————————————
  EASING
——————————————————————— */
$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);
$easeInOutFast: cubic-bezier(1, 0, 0, 1);
$authenticMotion: cubic-bezier(0.4, 0, 0.2, 1);