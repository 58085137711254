/* ————————————————————
  .grid-images
————————————————————— */
.grid-images {
}


/* ————————————————————
  .grid-images__item
——————————————————————— */
.grid-images__item {
  height: vr(15);
  position: relative;
  margin-left: vr(1);
  margin-right: vr(1);
  margin-bottom: vr(2);
}