/* ————————————————————
  .grid-references
——————————————————————— */
.grid-references {
  margin: 0;
}


/* ————————————————————
  .grid-references__item
——————————————————————— */
.grid-references__item {
  display: block;
  position: relative;
  background-color: $noir;
  color: #fff;
  padding-top: vr(2);
  overflow: hidden;


  /* HOVER
  ————————————————— */
  .no-touchevents &:hover {
    .grid-references__item__image {
      opacity: 0.5;
      transform: scale(1.01);
    }
  }
}


/* ————————————————————
  .grid-references__item__image
——————————————————————— */
.grid-references__item__image {
  @include cover();
  z-index: 1;
  opacity: 0.8;
  transition: opacity 1.2s ease, transform 1.2s ease;
}


/* ————————————————————
  .grid-references__item__content
——————————————————————— */
.grid-references__item__content {
  z-index: 2;
  min-height: vr(15);
  margin: 0;


  /* MEDIA QUERIES
  ————————————————— */
  @include breakpoint($breakpoint-tablet-large) {
    min-height: vr(20);
    padding-top: vr(3);
  }
}


/* ————————————————————
  .grid-references__item__link
——————————————————————— */
.grid-references__item__link {
  @include cover();
  z-index: 3;
}



/* ————————————————————
  .grid-references__item__logo
——————————————————————— */
.grid-references__item__logo {
  padding: 0 !important;
}

/* ————————————————————
  .grid-references__item__logo
——————————————————————— */
.grid-references__item__logo svg {
  fill: #fff;
  color: #fff;
  display: block;
  //margin-left: auto;
  width: vr(4);

  path {
    fill: #fff;
  }


  /* MEDIA QUERIES
  ————————————————— */
  @include breakpoint($breakpoint-tablet-large) {
    margin-top: vr(-2.2);
    margin-left: 0;
    width: vr(5);
  }
}


/* ————————————————————
  .grid-references__item__logo__image
——————————————————————— */
.grid-references__item__logo__image {
  width: vr(4);
  margin: 0;

  /* MEDIA QUERIES
  ————————————————— */
  @include breakpoint($breakpoint-tablet-large) {
    width: vr(5);
  }
}