/* ————————————————————
  .nav-main
——————————————————————— */
.nav-main {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  margin: 0;
  width: 100%;
  height: vr(4);
  @include z-index(main-nav);

  .l_container {
    display: flex;
    align-items: center;
  }
}



/* ————————————————————
  .nav-main__logo
——————————————————————— */
.nav-main__logo {
  display: inline-block;
  width: vr(7);
  height: vr(4);
  transition: opacity 0.3s $easeOutQuad;

  .no-touchevents &:hover {
    opacity: 0.8;
  }

  svg {
    margin: 0;
    width: 100%;
    height: 100%;
  }
}



/* ————————————————————
  .nav-main__linklist
——————————————————————— */
.nav-main__linklist {
  display: inline-block;
  width: auto;
  margin-top: 0;
  margin-left: auto;
  margin-right: 0;
}


/* ————————————————————
  .nav-main__linklist__item
——————————————————————— */
.nav-main__linklist__item {
  margin-left: vr(1);
  opacity: 0.6;
  transition: opacity 0.3s ease;
  display: none !important;


  /* HOVER
  ————————————————— */
  .no-touchevents &:hover {
    opacity: 1;
  }


  
  
  @include breakpoint($breakpoint-tablet) {
    display: inline-block !important;
    &:nth-child(n+3) {
      display: none !important;
    }
  }


  @include breakpoint($breakpoint-tablet-large) {
    &:nth-child(n+3) {
      display: inline-block !important;
    }
  }

}



/* ————————————————————
  .nav-main__btn
——————————————————————— */
.nav-main__btn {
  display: inline-block;
  margin-top: 0;
  margin-left: vr(1);
  opacity: 0.6;
  transition: opacity 0.3s ease;

  /* HOVER
  ————————————————— */
  .no-touchevents &:hover {
    opacity: 1;
  }
}