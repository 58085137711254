/* ————————————————————
  TRANSITION MIXIN
——————————————————————— */

%transition-opacity {
  transition: opacity 0.3s ease-out;
}

%transition-color {
  transition: color 0.3s ease-out;
}

%transition-background-color {
  transition: background-color 0.3s ease-out;
}

%transition-fadeinup {
  animation: fadeinup 0.8s ease-out forwards;
  opacity: 0;
}
@keyframes fadeinup {
  from {
    opacity: 0;
    transform: translate3d(0, 5px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animation-fadeinup {
  @extend %transition-fadeinup;
}

.-delay05s {
  animation-delay: 0.3s;
}



/* ————————————————————
  ANIMATION MIXIN
——————————————————————— */

/*@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(5%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRight {
  animation-name: fadeInRight;
  animation-duration: .5s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-timing-function: $easeOutQuad;
}




@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: .5s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-timing-function: $easeOutQuad;
}



@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn{
  animation-name: fadeIn;
  animation-duration: .5s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-timing-function: $easeOutQuad;
}*/

