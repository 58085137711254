/* ————————————————————
  CLEARFIX
——————————————————————— */
@mixin clearfix() {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}




/* ————————————————————
  VERTICAL RYTHM
——————————————————————— */
@function vr($factor: 1) {
  @return $document-line-height * $factor;
}

/* set body font size acording to px size
————————————————— */
@mixin bodyFontSize($base-font-size) {
  $base-font-size: strip-units($base-font-size);
  font-size: (($base-font-size / 16) * 100) + 0%;
}


/* show background baseline grid
————————————————— */
@mixin show-baseline-grid($document-line-height: $document-line-height) {
  &:after {
    content: "";
    z-index: 100000; /* should be enough right ?*/
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    $baseline-color: rgba(red, 0.8);
    background-image: linear-gradient(rgba(0, 0, 0, 0) 95%, $baseline-color 100%);
    background-size: 100% $document-line-height;
    background-position: top left; // Use to offset and center your grid
    pointer-events: none;
  }
}




/* ————————————————————
  Z-INDEX MIXIN
——————————————————————— */
@function z-index($key) {
  @return map-get($z-index, $key);
}
@mixin z-index($key) {
  z-index: z-index($key);
}





/* ————————————————————
  COLOR HELPERS
——————————————————————— */
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}





/* ————————————————————
  MATH HELPERS
——————————————————————— */
@function strip-units($number) {
  @return $number / ($number * 0 + 1);
}




/* ————————————————————
  LAYOUT HELPERS
——————————————————————— */
%cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
}
@mixin cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
}

%centerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@mixin centerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}




/* ————————————————————
  TEXT HELPERS
——————————————————————— */
%hide-text {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

%no-text-selection {
  user-select: none;
}

%ellipsis {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

%dontBreakWords {
  word-wrap: normal;
  word-break: keep-all;
  hyphens: none;
}
@mixin dontBreakWords {
  word-wrap: normal;
  word-break: keep-all;
  hyphens: none;
}

%dontBreakCols {
  -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
  page-break-inside: avoid; /* Firefox */
  break-inside: avoid; /* IE 10+ */
  display: inline-block;
}




/* ————————————————————
   FONTFACE MIXINS
——————————————————————— */
@mixin font-face($name, $fileName, $weight: null, $style: null) {
  @font-face {
    font-family: quote($name);
    src: url($fontsPath + $fileName + '.eot');
    src: url($fontsPath + $fileName + '.eot?#iefix') format('embedded-opentype'), url($fontsPath + $fileName + '.woff') format('woff'), url($fontsPath + $fileName + '.woff2') format('woff2'), url($fontsPath + $fileName + '.ttf') format('truetype');
    font-style: $style;
    font-weight: $weight;
  }
}