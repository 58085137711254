/* ————————————————————
  .m_outdated 
——————————————————————— */
.m_outdated{
  position: fixed;
  z-index:99999;
  top:0;
  left: 0;
  width:100%;
  padding: vr(3) vr(2);
  background-color: #fa3838;
  color: #fff;
  margin: 0;
}