/* ————————————————————
  BUTTON SPECIFIC RESET
——————————————————————— */
/* Firefox: Get rid of the inner focus border */
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button{
  overflow: visible;
  background-color: transparent;
  border: 0;
  text-transform: inherit;

  &:focus{
    outline: none;
  }
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
}






/* ————————————————————
  BTN COMPONENT
——————————————————————— */
.btn{
  -webkit-user-drag: none;
  -webkit-appearance: none;
  user-select: none;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  text-transform: none; //Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
  border: 0; // reset default border
  @extend %ellipsis;
  cursor: pointer;
  padding: 0 2em;
  @extend %transition-background-color;
  max-width: 100% !important;


  /* THEME
  ————————————————— */
  border-radius: 0;
  font-size: ms(-1);
  height: vr(1.5);
  line-height: vr(1.5);
  color: red;
  border: 1px solid red;
  background-color: transparent; 

  
  /* HOVER
  ————————————————— */
  .no-touchevents &:hover {
    text-decoration: none;
    background-color: pink;
    color: #fff;
     // cursor: pointer;
  } 
  
  &:focus{
    // border: 1px solid red;
    outline: 1px dotted;
  }


  /*  MODIFIER
   * ==================== */
  &.-primary{

  }

  &.-secondary{

  }

  &.-small{
    // font-size: ms(-1);
    // padding: 0 1em;
    // line-height: vr(1.5);
    // height: vr(1.5);
  }

  //disabled
  &.is--disabled,
  &:disabled{
    box-shadow: none;
    cursor: not-allowed; //Re-set default cursor for disabled elements.
    pointer-events: none;
    opacity: .4;
  }

  //active state
  &.-active{
    background-color: $active-color !important;
    cursor: not-allowed; 
    pointer-events: none; 
  }

  
  //block button
  &.-block{
    display: block;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
  }
  

  /*  THEME
   * ==================== */ 

}

