@charset "UTF-8";
/* ————————————————————
  LIBRARIES
——————————————————————— */
/* ————————————————————
  SETUP
——————————————————————— */
/* ————————————————————
  CLEARFIX
——————————————————————— */
/* ————————————————————
  VERTICAL RYTHM
——————————————————————— */
/* set body font size acording to px size
————————————————— */
/* show background baseline grid
————————————————— */
/* ————————————————————
  Z-INDEX MIXIN
——————————————————————— */
/* ————————————————————
  COLOR HELPERS
——————————————————————— */
/* ————————————————————
  MATH HELPERS
——————————————————————— */
/* ————————————————————
  LAYOUT HELPERS
——————————————————————— */
/* ————————————————————
  TEXT HELPERS
——————————————————————— */
.t_hide {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.btn, .tablist__nav.-long, .t_oneline {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.t_nowrap {
  word-wrap: normal;
  word-break: keep-all;
  hyphens: none; }

/* ————————————————————
   FONTFACE MIXINS
——————————————————————— */
/* ————————————————————
  FONT STACKS
——————————————————————— */
/* ————————————————————
  FONTS DECLARATIONS
——————————————————————— */
@font-face {
  font-family: "tabacG1";
  src: url("/assets/fonts/tabacglamg1-semibold-webfont.eot");
  src: url("/assets/fonts/tabacglamg1-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/tabacglamg1-semibold-webfont.woff") format("woff"), url("/assets/fonts/tabacglamg1-semibold-webfont.woff2") format("woff2"), url("/assets/fonts/tabacglamg1-semibold-webfont.ttf") format("truetype");
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: "tabacG4";
  src: url("/assets/fonts/tabacglamg4-regular-webfont.eot");
  src: url("/assets/fonts/tabacglamg4-regular-webfont.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/tabacglamg4-regular-webfont.woff") format("woff"), url("/assets/fonts/tabacglamg4-regular-webfont.woff2") format("woff2"), url("/assets/fonts/tabacglamg4-regular-webfont.ttf") format("truetype");
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: "vevey";
  src: url("/assets/fonts/vevey-webfont.eot");
  src: url("/assets/fonts/vevey-webfont.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/vevey-webfont.woff") format("woff"), url("/assets/fonts/vevey-webfont.woff2") format("woff2"), url("/assets/fonts/vevey-webfont.ttf") format("truetype");
  font-style: normal;
  font-weight: 400; }

/* ————————————————————
  COLORS
——————————————————————— */
/* Project colors
————————————————— */
/* Generic colors
————————————————— */
/* Social media colors
————————————————— */
/* Layout colors
————————————————— */
/* Text colors
————————————————— */
/* ————————————————————
  MODULAR SCALE & VERTICAL RHYTHM SETUP
——————————————————————— */
/* ————————————————————
  CUSTOM GRID SETUP
——————————————————————— */
/* ————————————————————
  BREAKPOINTS
——————————————————————— */
/*
$breakpoint-phone : min-width (480/strip-units($body-font-size))+0em; //480px
$breakpoint-tablet-small : min-width (600/strip-units($body-font-size))+0em;  //600px
$breakpoint-tablet : min-width (800/strip-units($body-font-size))+0em;
$breakpoint-desktop : min-width (1100/strip-units($body-font-size))+0em;
$breakpoint-desktop-large : min-width (1400/strip-units($body-font-size))+0em;
$breakpoint-container : min-width ( strip-units( $containerWidth ) * $typeFactor ) +2em;
$breakpoint-container--margins : min-width ( strip-units( $containerWidth ) * $typeFactor ) + 10em;
*/
/* ————————————————————
  CUSTOM GRID MIXINS
——————————————————————— */
/* COLUMNS COLS
————————————————— */
/* COLUMNS SPLIT
————————————————— */
/* ————————————————————
  Z_INDEX SETUP
——————————————————————— */
/* ————————————————————
  EASING
——————————————————————— */
/* ————————————————————
  BASE
——————————————————————— */
/* ————————————————————
  GLOBAL SETUP
  margins & cascade
——————————————————————— */
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0; }

* + * {
  margin-top: 1.5rem; }

body * {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit; }

body,
img,
br,
li,
dt,
dd,
th,
td,
option,
label,
*:first-child {
  margin-top: 0; }

/* html & body
  ————————————————— */
html {
  overflow-x: hidden !important;
  overflow-y: scroll;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  background-color: #000; }

body {
  overflow: hidden !important;
  position: relative;
  min-height: 100vh;
  background-color: #fff; }

/* ————————————————————
  MEDIA QUERIES FOR JS
——————————————————————— */
.indicator {
  position: absolute;
  top: -999em;
  left: -999em; }
  .indicator:after {
    content: "phone"; }
    @media (min-width: 37.5em) {
      .indicator:after {
        content: "tablet"; } }
    @media (min-width: 56.25em) {
      .indicator:after {
        content: "tablet-large"; } }
    @media (min-width: 75em) {
      .indicator:after {
        content: "desktop"; } }
    @media (min-width: 112.5em) {
      .indicator:after {
        content: "desktop-large"; } }

/* ————————————————————
  BASE LAYOUT ELEMENTS
  .l_band
——————————————————————— */
.l_band {
  position: relative;
  display: block;
  width: 100%;
  clear: both;
  padding: 0;
  /* MODIFIER COLORS
  ————————————————— */
  /* MODIFIER SIZE
  ————————————————— */ }
  .l_band:before, .l_band:after {
    content: "";
    display: table; }
  .l_band:after {
    clear: both; }
  .l_band.-noir5 {
    background-color: #f2f2f2 !important; }
  .l_band.-noir10 {
    background-color: #e6e6e6; }
  .l_band.-noir20 {
    background-color: #cccccc; }
  .l_band.-noir40 {
    background-color: #999999; }
  .l_band.-noir60 {
    background-color: #666666; }
  .l_band.-noir80 {
    background-color: #333333;
    color: #fff; }
  .l_band.-slim {
    padding: 2.25rem 0; }
  .l_band.-medium {
    padding: 3.75rem 0; }
  .l_band.-large {
    padding: 5.25rem 0; }

/* ————————————————————
  .l_container
——————————————————————— */
.l_container {
  max-width: 67.5rem;
  position: relative;
  /* MEDIA QUERIES
	————————————————— */
  /*  MODIFIERS
   * ==================== */ }
  @media (min-width: 82.16em) {
    .l_container {
      margin-left: auto;
      margin-right: auto; } }
  .l_container.-gutters {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    /* MEDIA QUERIES
    ————————————————— */ }
    @media (min-width: 82.16em) {
      .l_container.-gutters {
        margin-left: auto;
        margin-right: auto;
        padding-right: 1.5rem;
        padding-left: 1.5rem; } }
  .l_container.-text {
    margin-right: 1.5rem;
    margin-left: 1.5rem; }
    @media (min-width: 56.25em) {
      .l_container.-text {
        max-width: calc( 60% - 3rem);
        margin-left: auto;
        margin-right: auto; } }
  .l_container.-black {
    background-color: #000;
    color: #a8a8a8;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
    .l_container.-black::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 100%;
      background-color: #000;
      z-index: 0; }
    .l_container.-black::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 100%;
      background-color: #000;
      z-index: 0; }

/* ————————————————————
  .l_grid 
——————————————————————— */
.l_grid {
  display: flex;
  flex-wrap: wrap; }
  .l_grid.-reverse {
    flex-direction: reverse;
    border: 1px solid red; }

.l_grid__item {
  position: relative;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  width: 100%; }

/* ————————————————————
  .l_2col
——————————————————————— */
.l_grid.-size2 .l_grid__item {
  /* MEDIA QUERIES
    ————————————————— */ }
  @media (min-width: 56.25em) {
    .l_grid.-size2 .l_grid__item {
      width: calc( 50% - 3rem);
      margin-top: 0; } }

/* ————————————————————
  .l_4col
——————————————————————— */
.l_grid.-size4 .l_grid__item {
  width: calc( 50% - 3rem);
  margin-top: 1.5rem;
  /* MEDIA QUERIES
    ————————————————— */ }
  .l_grid.-size4 .l_grid__item.-square {
    height: 0;
    padding-bottom: calc( 50% - 3rem); }
  @media (min-width: 37.5em) {
    .l_grid.-size4 .l_grid__item {
      width: calc( 25% - 3rem);
      margin-top: 0; }
      .l_grid.-size4 .l_grid__item.-square {
        height: 0;
        padding-bottom: calc( 25% - 3rem); } }

/* ————————————————————
  .df_section
——————————————————————— */
.df_section {
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  /* MEDIA QUERIES
  ————————————————— */ }
  @media (min-width: 37.5em) {
    .df_section {
      margin-top: 6rem;
      margin-bottom: 3rem; } }

/* ————————————————————
  SETUP TYPOGRAPHY
——————————————————————— */
html {
  color: #636363;
  font-size: 93.75%;
  /* MEDIA QUERIES
  ————————————————— */ }
  @media (min-width: 37.5em) {
    html {
      font-size: 118.75%; } }

body {
  font-family: Verdana, Geneva, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  font-style: normal;
  font-smooth: auto;
  font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  hyphens: auto;
  word-wrap: break-word; }
  .fonts-loaded body {
    font-family: "tabacG4", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; }

/* ————————————————————
  selection colors
——————————————————————— */
::selection {
  background: #000;
  color: #fff;
  text-shadow: none; }

img::selection {
  background: transparent; }

body {
  -webkit-tap-highlight-color: transparent; }

/**
 * Links
 */
a {
  text-decoration: none;
  background-color: transparent; }
  p a {
    text-decoration: none;
    border-bottom: 1px solid #636363;
    transition: all 0.3s ease-out; }
    .no-touchevents p a:hover {
      text-decoration: none;
      color: #636363;
      border-bottom-color: #636363; }
  a:focus {
    outline: thin dotted; }
  .no-touchevents a:hover, a:active, a:focus {
    text-decoration: underline; }

/**
 * Titres
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: normal;
  line-height: inherit; }

.title {
  text-transform: uppercase;
  font-size: 3.81rem;
  line-height: 3rem;
  color: #272727; }
  .fonts-loaded .title {
    font-family: "vevey", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; }
  .title.-large {
    font-size: 4.77rem;
    line-height: 3rem; }
  .title.-larger {
    font-size: 3.81rem;
    line-height: 3.75rem;
    /* MEDIA QUERIES
    ————————————————— */ }
    @media (min-width: 37.5em) {
      .title.-larger {
        font-size: 7.45rem;
        line-height: 5.25rem; } }
  .title.-small {
    font-size: 3.05rem;
    line-height: 3rem; }

/**
 * paragraph
 */
.p.-large {
  font-size: 1.25rem;
  line-height: 1.8rem;
  color: #272727; }

.p.-small, .social-grid__item__date {
  font-size: 0.8rem; }

.p.-smaller {
  font-size: 0.64rem; }

.tc_text-grey {
  color: #636363; }

.tc_text-light {
  color: #a8a8a8; }

.tc_text-white {
  color: #fff; }

/**
 * em
 */
em {
  font-style: inherit; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: 600; }

/**
 * highlight
 */
/**
 * Code
 */
.t_raw h1,
.t_raw h2,
.t_raw h3,
.t_raw h4,
.t_raw h5,
.t_raw h6 {
  font-family: "tabacG1";
  font-weight: 700;
  color: #000;
  font-size: 1.25rem; }

.t_raw p + h1,
.t_raw p + h2,
.t_raw p + h3,
.t_raw p + h4,
.t_raw p + h5,
.t_raw p + h6,
.t_raw ul + h1,
.t_raw ul + h2,
.t_raw ul + h3,
.t_raw ul + h4,
.t_raw ul + h5,
.t_raw ul + h6 {
  margin-top: 2.25rem; }
  @media (min-width: 37.5em) {
    .t_raw p + h1,
    .t_raw p + h2,
    .t_raw p + h3,
    .t_raw p + h4,
    .t_raw p + h5,
    .t_raw p + h6,
    .t_raw ul + h1,
    .t_raw ul + h2,
    .t_raw ul + h3,
    .t_raw ul + h4,
    .t_raw ul + h5,
    .t_raw ul + h6 {
      margin-top: 4.5rem; } }

/* ————————————————————
  TRANSITION MIXIN
——————————————————————— */
.btn {
  transition: background-color 0.3s ease-out; }

.animation-fadeinup, .l_header__title-project,
.l_header__intro, .l_header__sup,
.l_header__title,
.l_header__anchornav {
  animation: fadeinup 0.8s ease-out forwards;
  opacity: 0; }

@keyframes fadeinup {
  from {
    opacity: 0;
    transform: translate3d(0, 5px, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

.-delay05s {
  animation-delay: 0.3s; }

/* ————————————————————
  ANIMATION MIXIN
——————————————————————— */
/*@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(5%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRight {
  animation-name: fadeInRight;
  animation-duration: .5s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-timing-function: $easeOutQuad;
}




@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: .5s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-timing-function: $easeOutQuad;
}



@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn{
  animation-name: fadeIn;
  animation-duration: .5s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-timing-function: $easeOutQuad;
}*/
/* ————————————————————
  BUTTON SPECIFIC RESET
——————————————————————— */
/* Firefox: Get rid of the inner focus border */
button::-moz-focus-inner {
  padding: 0;
  border: 0; }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible;
  background-color: transparent;
  border: 0;
  text-transform: inherit; }
  button:focus {
    outline: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/* ————————————————————
  BTN COMPONENT
——————————————————————— */
.btn {
  -webkit-user-drag: none;
  -webkit-appearance: none;
  user-select: none;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  border: 0;
  cursor: pointer;
  padding: 0 2em;
  max-width: 100% !important;
  /* THEME
  ————————————————— */
  border-radius: 0;
  font-size: 0.8rem;
  height: 2.25rem;
  line-height: 2.25rem;
  color: red;
  border: 1px solid red;
  background-color: transparent;
  /* HOVER
  ————————————————— */
  /*  MODIFIER
   * ==================== */
  /*  THEME
   * ==================== */ }
  .no-touchevents .btn:hover {
    text-decoration: none;
    background-color: pink;
    color: #fff; }
  .btn:focus {
    outline: 1px dotted; }
  .btn.is--disabled, .btn:disabled {
    box-shadow: none;
    cursor: not-allowed;
    pointer-events: none;
    opacity: .4; }
  .btn.-active {
    background-color: red !important;
    cursor: not-allowed;
    pointer-events: none; }
  .btn.-block {
    display: block;
    padding-right: 0;
    padding-left: 0;
    width: 100%; }

/* ————————————————————
  FORM SPECIFIC RESET
——————————————————————— */
html input[disabled] {
  cursor: default; }

select {
  text-transform: none; }

input {
  line-height: normal;
  /**
  * Fix the cursor style for Chrome's increment/decrement buttons. For certain
  * `font-size` values of the `input`, it causes the cursor style of the
  * decrement button to change from `default` to `text`.
   */
  /**
  * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
  * 2. Address `box-sizing` set to `border-box` in Safari and Chrome
  *    (include `-moz` to future-proof).
  */
  /**
  * Remove inner padding and search cancel button in Safari and Chrome on OS X.
  * Safari (but not Chrome) clips the cancel button when the search input has
  * padding (and `textfield` appearance).
  */ }
  input::-moz-focus-inner {
    border: 0;
    padding: 0; }
  input::-ms-clear {
    display: none; }
  input[type="checkbox"], input[type="radio"] {
    padding: 0; }
  input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
    height: auto; }
  input[type="search"] {
    -webkit-appearance: textfield;
    /* 1 */
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    /* 2 */
    box-sizing: content-box; }
  input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/* ————————————————————
    fieldset
  ——————————————————————— */
/* ————————————————————
    legend
  ——————————————————————— */
/* ————————————————————
    label
  ——————————————————————— */
label,
.form-field__error {
  display: block;
  font-size: 0.8rem; }

/* ————————————————————
    form-field
    (wrapper form label + input + error message)
  ——————————————————————— */
.form-field {
  border: 1px solid red;
  position: relative; }
  .form-field--group {
    display: inline-block;
    border: 1px solid red;
    margin-right: 1em;
    margin-top: 0 !important; }
    .form-field--group label {
      display: inline-block; }

/* ————————————————————
    form-field__error
  ——————————————————————— */
.form-field__error {
  display: none;
  color: red;
  margin-top: 0 !important;
  color: #fff;
  background-color: red;
  padding: 0 0.75rem;
  /* MODIFIER
    ————————————————— */ }
  .has-error .form-field__error {
    display: block;
    position: absolute;
    top: 100%;
    left: 0; }

/* ————————————————————
    inputs
  ——————————————————————— */
input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="week"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="color"],
select, textarea {
  border: 0;
  margin: 0 !important;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  background-color: #f2f2f2;
  color: #636363;
  text-indent: .5em;
  appearance: none;
  transition: all 0.3s ease-out;
  /* THEME
    ————————————————— */
  height: 3rem;
  /* focus */
  /* disabled */ }
  input[type="text"]:focus,
  input[type="password"]:focus,
  input[type="email"]:focus,
  input[type="url"]:focus,
  input[type="date"]:focus,
  input[type="month"]:focus,
  input[type="time"]:focus,
  input[type="datetime"]:focus,
  input[type="datetime-local"]:focus,
  input[type="week"]:focus,
  input[type="number"]:focus,
  input[type="search"]:focus,
  input[type="tel"]:focus,
  input[type="color"]:focus,
  select:focus, textarea:focus {
    outline: none !important;
    outline: 0;
    background-color: white; }
  input[type="text"][disabled],
  input[type="password"][disabled],
  input[type="email"][disabled],
  input[type="url"][disabled],
  input[type="date"][disabled],
  input[type="month"][disabled],
  input[type="time"][disabled],
  input[type="datetime"][disabled],
  input[type="datetime-local"][disabled],
  input[type="week"][disabled],
  input[type="number"][disabled],
  input[type="search"][disabled],
  input[type="tel"][disabled],
  input[type="color"][disabled],
  select[disabled], textarea[disabled] {
    opacity: .4;
    cursor: not-allowed; }

/* ————————————————————
    textarea
  ——————————————————————— */
textarea {
  height: auto;
  text-indent: 0;
  padding: .5em;
  resize: none;
  overflow: auto; }

/* ————————————————————
    placeholder
  ——————————————————————— */
/* ————————————————————
    select
  ——————————————————————— */
select {
  text-indent: 0;
  /* HOVER
    ————————————————— */ }
  .no-touchevents select:hover {
    cursor: pointer; }

optgroup {
  font-style: normal;
  font-weight: 400;
  border: 1px solid red; }

/* ————————————————————
    radio & checkboxes
  ——————————————————————— */
input[type="radio"],
input[type="checkbox"] {
  vertical-align: middle;
  margin-right: 1em;
  /* HOVER
    ————————————————— */ }
  input[type="radio"] + label,
  input[type="checkbox"] + label {
    font-size: 1rem; }
    .no-touchevents input[type="radio"] + label:hover, .no-touchevents
    input[type="checkbox"] + label:hover {
      cursor: pointer; }
  .no-touchevents input[type="radio"]:hover, .no-touchevents
  input[type="checkbox"]:hover {
    cursor: pointer; }

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: 1px thin #000; }

/* ————————————————————
  .icon
——————————————————————— */
.icon {
  line-height: inherit;
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  vertical-align: middle;
  margin-top: -.2em;
  /* MODIFIER
  ————————————————— */
  /* project specific theme
  ————————————————— */
  stroke: none;
  fill: currentColor; }
  .icon.-after {
    margin-left: .5em; }
  .icon.-before {
    margin-right: .5em; }
  .icon.-small {
    height: 0.75rem;
    width: 0.75rem; }
  .icon.-large {
    height: 2.25rem;
    width: 2.25rem; }

/*
.icon loading
*/
/*.icon.-loading{
  stroke-width: 1px;
  stroke: #fff;
  fill: none !important;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 37.52;
  stroke-dashoffset: 0px;
  opacity:0;
  animation-fill-mode: forwards;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-name: izyconnect-animation-loading;
  animation-duration: 1.5s;
  animation-timing-function: $easeOutQuad;
}



@keyframes izyconnect-animation-loading {
  0%{
    stroke-dashoffset: 37.52px;
    opacity:0;
  }

  50%{
    opacity:1;
    stroke-dashoffset: 0px;
  }

  100% {
    opacity:0;
    stroke-dashoffset: -37.52px;
  }
}*/
/* ————————————————————
  LISTS SPECIFIC RESET
——————————————————————— */
ul, ol {
  list-style: none;
  padding-left: 0; }

/* ————————————————————
  .linklist
——————————————————————— */
.linklist.-horizontale li, .breadcrumb li {
  display: inline-block;
  margin-right: -.27em; }

/* ————————————————————
  .breadcrumb
——————————————————————— */
.breadcrumb .breadcrumb__item:after {
  content: ">";
  padding: 0 .5em; }

.breadcrumb .breadcrumb__item:last-child:after {
  content: none; }

/* ————————————————————
  TABLES SPECIFIC RESET
——————————————————————— */
/**
 * @section Tables
 * Styling for tables
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 100%;
  width: 100%; }

th,
td {
  text-align: left;
  padding: 0.75rem 1.5rem; }

th {
  font-weight: 600;
  vertical-align: bottom;
  font-size: 0.8rem; }

td {
  vertical-align: top; }

/**
 * Adds zebra striping
 */
.table.-striped tbody tr:nth-child(odd) {
  background-color: #cccccc; }

/* ————————————————————
  .table.-responsive
  —
  Pure CSS responsive tables
  Adds label to each cell using the [data-label] attribute
  @link https://techblog.livingsocial.com/blog/2015/04/06/responsive-tables-in-pure-css/
——————————————————————— */
.table.-responsive {
  /* MEDIA QUERIES
  ————————————————— */ }
  .table.-responsive thead {
    display: none;
    visibility: hidden; }
  .table.-responsive tr {
    display: block; }
  .table.-responsive td {
    border: 0;
    display: block; }
    .table.-responsive td:before {
      content: attr(data-label);
      display: block;
      font-weight: 600; }
  @media (min-width: 37.5em) {
    .table.-responsive thead {
      display: table-header-group;
      visibility: visible; }
    .table.-responsive tr {
      display: table-row; }
    .table.-responsive td {
      display: table-cell; }
      .table.-responsive td:before {
        content: none; } }

/* ————————————————————
  COMPONENTS
——————————————————————— */
/* ————————————————————
  IMAGES SPECIFIC RESET
——————————————————————— */
/**
 * Remove border when inside `a` element in IE 8/9/10.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* ————————————————————
  IMAGE COMPONENT
——————————————————————— */
/* ————————————————————
  .image
——————————————————————— */
.image {
  overflow: hidden;
  position: relative;
  display: block;
  z-index: 1;
  margin: 0; }
  .image > img {
    position: relative;
    z-index: 2;
    display: block;
    width: 101%;
    min-width: 100%;
    height: auto;
    -webkit-backface-visibility: hidden;
    opacity: 1;
    transition: opacity 0.8s ease-out; }

/* ————————————————————
  .image.-lazy > img
——————————————————————— */
.image.-lazy > img {
  opacity: 0;
  /* fallback no js */ }
  .no-js .image.-lazy > img {
    opacity: 1; }

/* ————————————————————
  .image.has-placeholder
——————————————————————— */
.image.has-placeholder > img {
  position: absolute;
  top: 0;
  left: 0; }

/* ————————————————————
  .image.-cover
——————————————————————— */
.image.-cover {
  /* FALLBACK OBJECTFIT
    js add image to background of component image
    ————————————————— */ }
  .image.-cover > img {
    /* fallback no objectfit */
    /* fallback no js & no objectfit */ }
    .objectfit .image.-cover > img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      object-fit: cover;
      z-index: 2; }
    .no-objectfit .image.-cover > img {
      opacity: 0 !important; }
    .no-js.no-objectfit .image.-cover > img {
      opacity: 1 !important; }
  .no-objectfit .image.-cover {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }

/* ————————————————————
  .image__placeholder
——————————————————————— */
.image__placeholder {
  display: block;
  width: 100%;
  height: auto;
  background-size: auto 100%;
  z-index: 55;
  opacity: 1;
  /* MODIFIER
    ————————————————— */
  /* fallback */ }
  .-cover .image__placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 1;
    padding: 0 !important; }
  .no-objectfit .-cover.-lazy .image__placeholder {
    opacity: 1; }
  .no-objectfit .-cover .image__placeholder {
    opacity: 0; }
  .no-js .image__placeholder {
    opacity: 0 !important; }

/* ————————————————————
  .image__caption
——————————————————————— */
/*
.image__caption{
  font-size: ms(-1);
  margin-top: vr(.5);
}
*/
/* ————————————————————
  .tablist
  (wrapper tabs + sections)
——————————————————————— */
.tablist {
  border-bottom: 1px solid red; }

/* ————————————————————
  tablist panel
——————————————————————— */
.tablist section[role="tabpanel"][aria-hidden="true"] {
  display: none; }

/* ————————————————————
  .tablist__nav
——————————————————————— */
.tablist__nav {
  position: relative;
  /* MODIFIER
	————————————————— */ }
  .tablist__nav.-long {
    overflow: scroll;
    padding: 0 1.5rem;
    margin-left: -1.5rem;
    max-width: calc( 100% + 3rem);
    width: calc( 100% + 3rem);
    /*		&:after{
			content:"…";
			position: absolute;
			top:0;
			right:0;
			background-color: #fff;
			padding: 0 .25em;
		}*/ }

/* ————————————————————
  .tablist__nav tab
——————————————————————— */
.tablist__nav [role="tab"] {
  margin-top: 0 !important;
  margin-right: -.27em;
  padding: .5em 1em;
  border: 1px solid #fff;
  border-bottom: 0; }

.tablist__nav [role="tab"][aria-selected="true"] {
  border-color: red; }

[data-lazy-bg] {
  opacity: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  /* MODIFIER
	————————————————— */ }
  [data-lazy-bg].is-loaded {
    opacity: 1; }
  [data-lazy-bg].fade-in {
    transition: opacity 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s; }
    .no-touchevents [data-lazy-bg].fade-in:hover {
      transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s; }

/* ————————————————————
  MODULES
——————————————————————— */
/* ————————————————————
  .l_footer
——————————————————————— */
.l_footer {
  color: #a8a8a8;
  font-size: 0.8rem;
  line-height: 1.6;
  padding-top: 3rem;
  padding-bottom: 1.5rem;
  margin-top: 4.5rem; }

/* ————————————————————
  .l_footer__logo
——————————————————————— */
.l_footer__logo {
  display: block;
  width: 10.5rem;
  height: 3rem;
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  .no-touchevents .l_footer__logo:hover {
    opacity: 0.8; }
  .l_footer__logo svg {
    width: 100%;
    height: 100%; }

.l_footer > .l_grid {
  align-items: center;
  margin-top: 3rem; }

/* ————————————————————
  .l_footer__columns
——————————————————————— */
.l_footer__columns {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem; }

/* ————————————————————
  .l_footer__column
——————————————————————— */
.l_footer__column {
  width: calc( 100% - 3rem);
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  flex-shrink: 0;
  /* MODIFIER
  ————————————————— */ }
  @media (min-width: 37.5em) {
    .l_footer__column {
      margin-top: 0rem;
      width: calc( 33.33% - 3rem); } }
  @media (min-width: 75em) {
    .l_footer__column {
      width: calc( 25% - 3rem); } }

/* ————————————————————
  .l_footer__copyright
——————————————————————— */
.l_footer__copyright {
  width: calc( 100% - 3rem);
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  color: #a8a8a8;
  font-size: 0.64rem;
  margin-top: 3rem; }
  .l_footer__copyright p {
    display: inline-block;
    border: 1px solid red; }
    .l_footer__copyright p:first-child {
      margin-right: auto;
      margin-top: 0; }

/* ————————————————————
  .l_footer__language
——————————————————————— */
.l_footer__language {
  display: block;
  margin: 0; }
  .l_footer__language a {
    text-decoration: none;
    border: 0;
    /* HOVER
    ————————————————— */ }
    .no-touchevents .l_footer__language a:hover {
      opacity: 0.8; }
  @media (min-width: 56.25em) {
    .l_footer__language {
      text-align: right; } }

/* ————————————————————
  .l_header.-reference
——————————————————————— */
.l_header.-reference .l_container {
  padding-top: 12rem;
  padding-bottom: 3rem;
  text-align-last: left;
  position: relative;
  /* MEDIA QUERIES
  ————————————————— */ }
  @media (min-width: 37.5em) {
    .l_header.-reference .l_container {
      padding-top: 15rem;
      padding-bottom: 10.5rem; } }
  .l_header.-reference .l_container .l_grid__item {
    margin-left: auto;
    position: relative;
    z-index: 2; }

.l_header__title-project,
.l_header__intro {
  position: relative;
  z-index: 2; }

.l_header.-reference .l_header__sup {
  color: #fff;
  opacity: 0.8; }

.l_header.-reference .l_header__image {
  width: 100%;
  z-index: 1;
  opacity: 0.4;
  /* MEDIA QUERIES
  ————————————————— */ }
  @media (min-width: 37.5em) {
    .l_header.-reference .l_header__image {
      width: 60%;
      opacity: 0.6; } }

/* ————————————————————
  .l_header__title-project
——————————————————————— */
.l_header__title-project {
  color: #fff;
  text-align: left; }

/* ————————————————————
  .l_header__intro
——————————————————————— */
.l_header__intro {
  margin-top: 3rem;
  text-align: left;
  font-size: 1.25rem;
  line-height: 1.95rem;
  opacity: 0.6 !important;
  color: #cccccc;
  margin-top: 0.75rem;
  /* MEDIA QUERIES
  ————————————————— */ }
  @media (min-width: 37.5em) {
    .l_header__intro {
      margin-top: 3rem; } }

/* ————————————————————
  .l_header
——————————————————————— */
.l_header {
  text-align: center;
  background-color: #000;
  color: #fff;
  margin: 0;
  position: relative;
  z-index: 1; }

/* ————————————————————
  .l_header__container
—————————————————————— */
.l_header__container {
  padding-top: 18rem;
  padding-bottom: 4.5rem;
  /* MODIFIER
  ————————————————— */ }
  .-imagecover .l_header__container {
    position: initial; }
  .-home .l_header__container {
    padding-top: 7.5rem;
    padding-bottom: 9rem;
    /* MEDIA QUERIES
    ————————————————— */ }
    @media (min-width: 37.5em) {
      .-home .l_header__container {
        padding-top: 15rem;
        padding-bottom: 13.5rem; } }
  @media (min-width: 56.25em) {
    .l_header__container {
      padding-top: 13.5rem; } }

.l_header__sup,
.l_header__title,
.l_header__anchornav {
  position: relative;
  z-index: 2; }

/* ————————————————————
  .l_header__sup
——————————————————————— */
.l_header__sup {
  color: #a8a8a8;
  font-size: 0.8rem; }

/* ————————————————————
  .l_header__title
——————————————————————— */
.l_header__title {
  font-size: 1.95rem;
  line-height: 3rem;
  font-family: tabacG1;
  font-weight: 700;
  padding: 0 calc( 14.29% - 3rem);
  word-wrap: normal;
  word-break: keep-all;
  hyphens: none;
  /* MEDIA QUERIES
  ————————————————— */ }
  .-home .l_header__title {
    font-size: 1.56rem;
    line-height: 2.25rem; }
  @media (min-width: 56.25em) {
    .l_header__title {
      font-size: 2.44rem;
      line-height: 3rem; } }
  @media (min-width: 75em) {
    .l_header__title {
      padding: 0 calc( 14.29% - 3rem);
      font-size: 3.05rem;
      line-height: 3.75rem;
      /* MODIFIER
    ————————————————— */ }
      .-home .l_header__title {
        font-size: 2.44rem;
        line-height: 3rem; } }

/* ————————————————————
  .l_header__anchornav
——————————————————————— */
.l_header__anchornav {
  font-size: 0.64rem;
  color: #a8a8a8;
  text-transform: uppercase;
  letter-spacing: 0.15em; }

/* ————————————————————
  .l_header__anchornav__item
——————————————————————— */
.l_header__anchornav__item {
  margin-left: 1.5rem; }

/* ————————————————————
  .l_header__image
——————————————————————— */
.l_header__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  height: 100%;
  z-index: 1;
  opacity: 0.5;
  /* MEDIA QUERIES
  ————————————————— */ }
  .-imagecover .l_header__image {
    width: 100%;
    opacity: 0.3; }
  @media (min-width: 37.5em) {
    .l_header__image {
      width: 50%; } }

/* ————————————————————
  .l_header__scroll-helper
——————————————————————— */
.l_header__scroll-helper {
  text-transform: uppercase;
  font-size: 0.64rem;
  letter-spacing: 2px;
  opacity: 0.4;
  display: none;
  align-items: center;
  position: absolute;
  bottom: -65px;
  left: calc( 14.29% - 3rem);
  transform: rotate(-90deg);
  transition: opacity 0.3s ease-out;
  transform-origin: bottom left;
  z-index: 4;
  user-select: none;
  /* HOVER
  ————————————————— */ }
  .l_header__scroll-helper::before {
    content: '';
    display: inline-block;
    width: 100px;
    height: 1px;
    background-color: #e6e6e6;
    margin-right: 0.75rem;
    margin-top: -2px;
    vertical-align: middle; }
  @media (min-width: 37.5em) {
    .l_header__scroll-helper {
      display: inline-block; } }

/* ————————————————————
  .m_outdated 
——————————————————————— */
.m_outdated {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  padding: 4.5rem 3rem;
  background-color: #fa3838;
  color: #fff;
  margin: 0; }

/* ————————————————————
  .tab
——————————————————————— */
.tab {
  position: relative;
  overflow: hidden;
  margin-top: 0; }
  .tab + .tab {
    border-top: 1px solid #e6e6e6; }

/* ————————————————————
  .tab__input
——————————————————————— */
.tab__input {
  position: absolute;
  opacity: 0;
  z-index: -1; }

/* ————————————————————
  .tab__label
——————————————————————— */
.tab__label {
  position: relative;
  display: block;
  cursor: pointer;
  font-size: 1.25rem !important;
  line-height: 4.5rem; }
  .tab__label::after {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: 4.5rem;
    line-height: 4.5rem;
    text-align: center;
    transition: all 0.35s; }
    .tab input[type=checkbox] + .tab__label::after {
      content: "+"; }
    .tab input[type=checkbox]:checked + .tab__label::after {
      transform: rotate(135deg); }

/* ————————————————————
  .tab__content
——————————————————————— */
.tab__content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.35s, opacity 0.3s ease;
  margin-top: 0;
  opacity: 0; }
  .tab__content p {
    margin: 0 0 1.5rem 0; }
  .tab input:checked ~ .tab__content {
    max-height: 30em;
    opacity: 1; }

/* ————————————————————
  .cta-reference
——————————————————————— */
.cta-reference {
  position: relative;
  display: block;
  padding-top: 4.5rem;
  padding-bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column-reverse;
  overflow: hidden;
  background-color: #000;
  /* MEDIA QUERIES
  ————————————————— */
  /* HOVER
  ————————————————— */ }
  .no-touchevents .cta-reference:hover .cta-reference__image {
    opacity: 0.5;
    transform: scale(1.01); }
  .no-touchevents .cta-reference:hover.-nohover .cta-reference__image {
    opacity: 1;
    transform: none; }

/* ————————————————————
  .cta-reference__image
——————————————————————— */
.cta-reference__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  z-index: 1;
  opacity: 1;
  transform-origin: center center;
  will-change: transform;
  transition: opacity 1.2s ease, transform 1.2s ease; }

/* ————————————————————
  .cta-reference__content
——————————————————————— */
.cta-reference__content {
  z-index: 2;
  color: #fff;
  margin-left: auto;
  min-height: 28.5rem;
  padding: 1.5rem;
  /* MEDIA QUERIES
  ————————————————— */ }
  .cta-reference__content .title {
    color: inherit; }
  .cta-reference__content .p {
    opacity: 0.6; }
  @media (min-width: 56.25em) {
    .cta-reference__content {
      padding: 0rem;
      min-height: 22.5rem; } }

/* ————————————————————
  .cta-reference__link
——————————————————————— */
.cta-reference__link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  z-index: 3;
  /* HOVER
  ————————————————— */ }
  .no-touchevents .cta-reference__link:hover {
    text-decoration: none; }

/* ————————————————————
  .grid-images
————————————————————— */
/* ————————————————————
  .grid-images__item
——————————————————————— */
.grid-images__item {
  height: 22.5rem;
  position: relative;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-bottom: 3rem; }

/* ————————————————————
  .grid-clients
——————————————————————— */
.grid-logos {
  margin-top: 3rem; }

/* ————————————————————
  .grid-clients__item
——————————————————————— */
.grid-logos__item {
  position: relative; }

.grid-logos__item__image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 85%;
  /* MEDIA QUERIES
  ————————————————— */ }
  @media (min-width: 75em) {
    .grid-logos__item__image {
      width: 65%; } }

/* ————————————————————
  .grid-profile
——————————————————————— */
.grid-profile {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 3rem; }

/* ————————————————————
  .grid-profile__item
——————————————————————— */
.grid-profile__item {
  margin: 0;
  width: 45%;
  margin-bottom: 3rem; }

/* ————————————————————
  .grid-profile__image
——————————————————————— */
.grid-profile__image {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  margin-bottom: 1.5rem; }

/* ————————————————————
  .grid-profile__name
——————————————————————— */
.grid-profile__name {
  color: #000;
  font-family: "tabacG1";
  font-weight: 700; }

/* ————————————————————
  .grid-push
——————————————————————— */
.grid-push {
  margin-top: -3rem;
  /* MEDIA QUERIES
  ————————————————— */ }
  @media (min-width: 37.5em) {
    .grid-push {
      margin-top: -6rem; }
      .grid-push.-rebound {
        margin-top: 3rem;
        padding-top: 3rem;
        padding-bottom: 1.5rem;
        margin-bottom: 0; }
        .grid-push.-rebound + .l_footer {
          margin-top: 0; } }

/* ————————————————————
  .grid-push__item
——————————————————————— */
.grid-push__item {
  position: relative;
  background-color: #000;
  overflow: hidden;
  /* HOVER
  ————————————————— */ }
  .no-touchevents .grid-push__item:hover .grid-push__image {
    opacity: 0.2;
    transform: scale(1.01); }

/* ————————————————————
  .grid-push__image
——————————————————————— */
.grid-push__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  z-index: 1;
  opacity: 0.6;
  transform-origin: center center;
  will-change: transform;
  transition: opacity 1.2s ease, transform 1.2s ease 0.3s; }

/* ————————————————————
  .grid-push__content
——————————————————————— */
.grid-push__content {
  z-index: 2;
  color: #fff;
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 7.5rem;
  padding-bottom: 4.5rem;
  /* MODIFIER
  ————————————————— */
  /* MEDIA QUERIES
  ————————————————— */ }
  .grid-push__content .grid-push__title {
    color: inherit;
    display: block;
    font-family: 'tabacG1';
    font-weight: 700;
    font-size: 1.56rem; }
    .-rebound .grid-push__content .grid-push__title {
      font-size: 1.25rem; }
  .grid-push__content .grid-push__chapo {
    color: inherit;
    opacity: 0.8;
    font-weight: 300;
    margin-top: 0.75rem; }
    .-rebound .grid-push__content .grid-push__chapo {
      margin-top: 0.75rem; }
  .-rebound .grid-push__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 7.5rem;
    padding-bottom: 4.5rem; }
  @media (min-width: 37.5em) {
    .grid-push__content {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-top: 18rem;
      padding-bottom: 9rem; }
      .-rebound .grid-push__content {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 7.5rem;
        padding-bottom: 4.5rem; } }

/* ————————————————————
   .grid-push__link
 ——————————————————————— */
.grid-push__link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  z-index: 3;
  /* HOVER
   ————————————————— */ }
  .no-touchevents .grid-push__link:hover {
    text-decoration: none; }

/* ————————————————————
  .grid-references
——————————————————————— */
.grid-references {
  margin: 0; }

/* ————————————————————
  .grid-references__item
——————————————————————— */
.grid-references__item {
  display: block;
  position: relative;
  background-color: #000;
  color: #fff;
  padding-top: 3rem;
  overflow: hidden;
  /* HOVER
  ————————————————— */ }
  .no-touchevents .grid-references__item:hover .grid-references__item__image {
    opacity: 0.5;
    transform: scale(1.01); }

/* ————————————————————
  .grid-references__item__image
——————————————————————— */
.grid-references__item__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  z-index: 1;
  opacity: 0.8;
  transition: opacity 1.2s ease, transform 1.2s ease; }

/* ————————————————————
  .grid-references__item__content
——————————————————————— */
.grid-references__item__content {
  z-index: 2;
  min-height: 22.5rem;
  margin: 0;
  /* MEDIA QUERIES
  ————————————————— */ }
  @media (min-width: 56.25em) {
    .grid-references__item__content {
      min-height: 30rem;
      padding-top: 4.5rem; } }

/* ————————————————————
  .grid-references__item__link
——————————————————————— */
.grid-references__item__link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  z-index: 3; }

/* ————————————————————
  .grid-references__item__logo
——————————————————————— */
.grid-references__item__logo {
  padding: 0 !important; }

/* ————————————————————
  .grid-references__item__logo
——————————————————————— */
.grid-references__item__logo svg {
  fill: #fff;
  color: #fff;
  display: block;
  width: 6rem;
  /* MEDIA QUERIES
  ————————————————— */ }
  .grid-references__item__logo svg path {
    fill: #fff; }
  @media (min-width: 56.25em) {
    .grid-references__item__logo svg {
      margin-top: -3.3rem;
      margin-left: 0;
      width: 7.5rem; } }

/* ————————————————————
  .grid-references__item__logo__image
——————————————————————— */
.grid-references__item__logo__image {
  width: 6rem;
  margin: 0;
  /* MEDIA QUERIES
  ————————————————— */ }
  @media (min-width: 56.25em) {
    .grid-references__item__logo__image {
      width: 7.5rem; } }

/* ————————————————————
  .grid-services
——————————————————————— */
/* ————————————————————
  .grid-services__item
—————————————————————— */
.grid-services__item {
  position: relative;
  margin-bottom: 1.5rem;
  overflow: hidden;
  background-color: #000;
  /* HOVER
  ————————————————— */ }
  .no-touchevents .grid-services__item:hover .grid-services__image {
    opacity: 0.5;
    transform: scale(1.01); }

/* ————————————————————
  .grid-services__image
——————————————————————— */
.grid-services__image {
  display: block;
  height: 0;
  padding-bottom: 50%;
  z-index: 1;
  opacity: 0.8;
  transition: opacity 1.2s ease, transform 1.2s ease; }

/* ————————————————————
  .grid-services__link
——————————————————————— */
.grid-services__link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  z-index: 2;
  margin: 0;
  background-color: transparent;
  font-size: 0.8rem;
  color: #fff;
  padding: 9rem 1.5rem 1.5rem 1.5rem;
  text-decoration: none;
  /* HOVER
  ————————————————— */ }
  .no-touchevents .grid-services__link:hover {
    text-decoration: none; }

/* ————————————————————
  .grid-services__link__title
——————————————————————— */
.grid-services__link__title {
  font-weight: 700;
  font-family: tabacG1;
  font-size: 1.25rem; }

/* ————————————————————
  .grid-services__link__roles
——————————————————————— */
.grid-services__link__roles {
  opacity: 0.8; }

html.show-mobile-nav {
  overflow-y: hidden !important; }

/* ————————————————————
  .nav-mobile-wrapper
——————————————————————— */
.nav-mobile-wrapper {
  z-index: 15;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  position: fixed;
  background-color: transparent;
  pointer-events: none; }
  .nav-mobile-wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    background-color: #000;
    opacity: 0;
    transition: opacity 0.15s ease 0.1s; }
  .show-mobile-nav .nav-mobile-wrapper {
    pointer-events: auto; }
    .show-mobile-nav .nav-mobile-wrapper::before {
      transition: opacity 0.3s ease;
      opacity: 0.9; }

/* ————————————————————
  .nav-mobile
——————————————————————— */
.nav-mobile {
  z-index: 2;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  overflow: scroll;
  background-color: #000;
  color: #fff;
  padding: 2.25rem;
  opacity: 0;
  width: 90%;
  transition: transform 0.2s ease-out, opacity 0.15s ease-out;
  transform: translate3d(10%, 0, 0);
  /* MEDIA QUERIES
   ————————————————— */ }
  .show-mobile-nav .nav-mobile {
    transition: transform 0.3s ease-out 0.3s, opacity 0.3s ease-out 0.3s;
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  @media (min-width: 56.25em) {
    .nav-mobile {
      padding: 3.75rem;
      width: 45%; } }

/* ————————————————————
  .nav-mobile__linklist
——————————————————————— */
.nav-mobile__linklist {
  font-size: 1.95rem;
  line-height: 2.7rem;
  margin-top: 6rem;
  /* MEDIA QUERIES
  ————————————————— */ }
  @media (min-width: 56.25em) {
    .nav-mobile__linklist {
      font-size: 1.56rem;
      line-height: 2.1rem; } }

/* ————————————————————
  .nav-mobile__linklist__item
——————————————————————— */
.nav-mobile__linklist__item {
  margin-bottom: 0.75rem; }

/* ————————————————————
  .nav-mobile__contacts
——————————————————————— */
.nav-mobile__contacts {
  margin-top: 3rem;
  color: #a8a8a8; }

/* ————————————————————
  .nav-mobile__btn
——————————————————————— */
.nav-mobile__btn {
  position: absolute;
  top: 2.25rem;
  right: 1.5rem;
  margin: 0;
  opacity: 0.4;
  transition: opacity 0.3s ease;
  /* HOVER
  ————————————————— */ }
  .no-touchevents .nav-mobile__btn:hover {
    opacity: 1; }

/* ————————————————————
  .nav-mobile__btn-large
——————————————————————— */
.nav-mobile__btn-large {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background-color: red;
  border: 3px solid green;
  width: 80%;
  z-index: 1;
  opacity: 0; }

/* ————————————————————
  .nav-main
——————————————————————— */
.nav-main {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  margin: 0;
  width: 100%;
  height: 6rem;
  z-index: 14; }
  .nav-main .l_container {
    display: flex;
    align-items: center; }

/* ————————————————————
  .nav-main__logo
——————————————————————— */
.nav-main__logo {
  display: inline-block;
  width: 10.5rem;
  height: 6rem;
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  .no-touchevents .nav-main__logo:hover {
    opacity: 0.8; }
  .nav-main__logo svg {
    margin: 0;
    width: 100%;
    height: 100%; }

/* ————————————————————
  .nav-main__linklist
——————————————————————— */
.nav-main__linklist {
  display: inline-block;
  width: auto;
  margin-top: 0;
  margin-left: auto;
  margin-right: 0; }

/* ————————————————————
  .nav-main__linklist__item
——————————————————————— */
.nav-main__linklist__item {
  margin-left: 1.5rem;
  opacity: 0.6;
  transition: opacity 0.3s ease;
  display: none !important;
  /* HOVER
  ————————————————— */ }
  .no-touchevents .nav-main__linklist__item:hover {
    opacity: 1; }
  @media (min-width: 37.5em) {
    .nav-main__linklist__item {
      display: inline-block !important; }
      .nav-main__linklist__item:nth-child {
        display: none !important; } }
  @media (min-width: 56.25em) {
    .nav-main__linklist__item:nth-child(n+3) {
      display: inline-block !important; } }

/* ————————————————————
  .nav-main__btn
——————————————————————— */
.nav-main__btn {
  display: inline-block;
  margin-top: 0;
  margin-left: 1.5rem;
  opacity: 0.6;
  transition: opacity 0.3s ease;
  /* HOVER
  ————————————————— */ }
  .no-touchevents .nav-main__btn:hover {
    opacity: 1; }

/* ————————————————————
  .rebound
——————————————————————— */
.rebound {
  margin-top: 3rem;
  padding-top: 3rem !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important; }
  .rebound .title {
    color: #fff; }
  .rebound + .l_footer {
    margin-top: 0; }

/* ————————————————————
  .rebound__divider
——————————————————————— */
.rebound__divider {
  display: block;
  width: calc( 100% - 3rem);
  height: 1px;
  background-color: #fff;
  opacity: 0.1;
  border: 0;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto; }

/* ————————————————————
  .socials
——————————————————————— */
.socials {
  padding: 4.5rem 0 3rem 0; }
  .socials::before {
    content: '';
    display: block;
    width: 400%;
    height: 1px;
    background-color: #f2f2f2;
    position: absolute;
    top: 0;
    left: -200%; }

/* ————————————————————
  .socials__links
——————————————————————— */
.socials__links {
  font-size: 0.8rem;
  text-transform: uppercase;
  color: #a8a8a8;
  /* MEDIA QUERIES
  ————————————————— */ }
  .socials__links li {
    margin-right: 1.5rem !important; }
  @media (min-width: 37.5em) {
    .socials__links {
      text-align: right; }
      .socials__links li {
        margin-left: 0.75rem;
        margin-right: 0 !important; } }

/* ————————————————————
  .social-grid
——————————————————————— */
.social-grid {
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 37.5em) {
    .social-grid {
      margin-top: 3rem; } }

/* ————————————————————
  .social-grid__item
——————————————————————— */
.social-grid__item {
  position: relative;
  /* MEDIA QUERIES
  ————————————————— */ }

/* ————————————————————
  .social-grid__item__image
——————————————————————— */
.social-grid__item__image {
  display: block;
  width: 100%; }

/* ————————————————————
  .social-grid__item__date
——————————————————————— */
.social-grid__item__date {
  display: block;
  color: #a8a8a8;
  margin-top: 0.38rem; }

/* ————————————————————
  .social-grid__item__link
——————————————————————— */
.social-grid__item__link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  z-index: 2; }

/* ————————————————————
  .testimonial
——————————————————————— */
.testimonial {
  color: #fff;
  padding: 6rem 3rem !important;
  /* MEDIA QUERIES
   ————————————————— */ }
  @media (min-width: 56.25em) {
    .testimonial {
      padding-left: calc( 37.5% - 3rem) !important;
      padding-bottom: 6rem !important;
      padding-top: 7.5rem !important; } }

/* ————————————————————
  .testimonial__chapo
——————————————————————— */
.testimonial__chapo {
  font-size: 1.56rem;
  line-height: 2.1rem;
  font-family: "tabacG1";
  font-weight: 700;
  display: block;
  color: #fff;
  position: relative;
  z-index: 2;
  /* MEDIA QUERIES
   ————————————————— */ }
  .testimonial__chapo .icon {
    position: absolute;
    z-index: 0;
    top: -2.25rem;
    left: -2.25rem;
    width: 4.5rem;
    height: 4.5rem;
    opacity: 0.1; }
  @media (min-width: 37.5em) {
    .testimonial__chapo {
      font-size: 1.56rem;
      line-height: 2.25rem; }
      .testimonial__chapo .icon {
        top: -3rem;
        left: -3rem;
        width: 6rem;
        height: 6rem; } }

/* ————————————————————
  .testimonial__body
——————————————————————— */
/* ————————————————————
  .testimonial__author
——————————————————————— */
.testimonial__author {
  margin-top: 3rem;
  display: block;
  font-style: normal; }
  .testimonial__author::before {
    content: '— '; }

/* ————————————————————
  UTILITIES  (need to overwrite cascade)
——————————————————————— */
/* ————————————————————
  STATE UTILITIES CLASSES
——————————————————————— */
.is--visible {
  display: block; }

.is--hidden {
  display: none !important; }

/*
.u_message{
  padding: vr(1);
  border: 1px solid $noir-20;

  &--warning{
    // border: 1px solid lighten($rouge, 10%);
    // color: $rouge;
  }
}
*/
/* ————————————————————
  LAYOUT UTILITIES CLASSES
——————————————————————— */
.l_col-1 {
  float: left;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  width: calc(100% - 3rem); }

.l_col-2 {
  float: left;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  width: calc(50% - 3rem); }

.l_col-3 {
  float: left;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  width: calc(33.33% - 3rem); }

.l_col-4 {
  float: left;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  width: calc(25% - 3rem); }

.l_col-5 {
  float: left;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  width: calc(20% - 3rem); }

.l_col-6 {
  float: left;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  width: calc(16.67% - 3rem); }

.l_db {
  display: block; }

.l_di {
  display: inline-block; }

.l_dib {
  display: inline-block; }

.l_cf:before, .l_cf:after {
  content: "";
  display: table; }

.l_cf:after {
  clear: both; }

.l_cb {
  clear: both; }

.l_cl {
  clear: left; }

.l_cr {
  clear: right; }

.l_fl {
  float: left; }

.l_fr {
  float: right; }

.l_fn {
  float: none; }

.l_ml-auto {
  margin-left: auto; }

.l_mr-auto {
  margin-right: auto; }

.l_mx-auto {
  margin-left: auto;
  margin-right: auto; }

.l_mt0 {
  margin-top: 0; }

.l_mt05 {
  margin-top: 0.75rem; }

.l_mt1 {
  margin-top: 1.5rem; }

.l_mt2 {
  margin-top: 3rem; }

.l_mt3 {
  margin-top: 4.5rem; }

.l_mt4 {
  margin-top: 6rem; }

.l_mr1 {
  margin-right: 1.5rem; }

.l_mr2 {
  margin-right: 3rem; }

.l_mr3 {
  margin-right: 4.5rem; }

.l_mb1 {
  margin-bottom: 1.5rem; }

.l_mb2 {
  margin-bottom: 3rem; }

.l_mb3 {
  margin-bottom: 4.5rem; }

.l_ml1 {
  margin-left: 1.5rem; }

.l_ml2 {
  margin-left: 3rem; }

.l_ml3 {
  margin-left: 4.5rem; }

.l_pt1 {
  padding-top: 1.5rem; }

.l_pt2 {
  padding-top: 3rem; }

.l_pt3 {
  padding-top: 4.5rem; }

.l_pt4 {
  padding-top: 6rem; }

.l_pb1 {
  padding-bottom: 1.5rem; }

.l_pb2 {
  padding-bottom: 3rem; }

.l_pb3 {
  padding-bottom: 4.5rem; }

.l_pb4 {
  padding-bottom: 6rem; }

.u_float--right {
  float: right; }

.u_float--left {
  float: left; }

/* ————————————————————
  TEXT UTILITIES CLASSES
——————————————————————— */
.t_ {
  /*  &--exposant{
    font-size: .6em;
  }*/ }
  .t_sans {
    font-family: sans-serif; }
  .t_serif {
    font-family: serif; }
  .t_oneline {
    max-width: 100%;
    display: inline-block; }
  .t_right {
    text-align: right; }
  .t_left {
    text-align: left; }
  .t_center {
    text-align: center; }
  .t_justify {
    text-align: justify; }
  .t_lowercase {
    text-transform: lowercase; }
  .t_uppercase {
    text-transform: uppercase; }
  .t_ms-2 {
    font-size: 0.64rem; }
  .t_ms-1 {
    font-size: 0.8rem; }
  .t_ms0 {
    font-size: 1rem; }
  .t_ms1 {
    font-size: 1.25rem; }
  .t_ms2 {
    font-size: 1.56rem; }
  .t_ms3 {
    font-size: 1.95rem; }
  .t_ms4 {
    font-size: 2.44rem; }
  .t_ms5 {
    font-size: 3.05rem; }
  .t_light {
    font-weight: 300; }
  .t_bold {
    font-weight: 600; }
  .t_reset-weight {
    font-weight: 400; }
  .t_italic {
    font-style: italic; }
  .t_reset-style {
    font-style: normal; }
  .t_noir80 {
    color: #333333; }
  .t_noir60 {
    color: #666666; }
  .t_noir40 {
    color: #999999; }
  .t_noir20 {
    color: #cccccc; }
  .t_reset-decoration {
    text-decoration: none; }
  .t_underline {
    text-decoration: underline; }
  .t_linetrough {
    text-decoration: line-through; }

/* ————————————————————
  PAGE SPECIFIC
——————————————————————— */
/*@import
	"05-pages/_produit";*/
