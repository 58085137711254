/* ————————————————————
  IMAGES SPECIFIC RESET
——————————————————————— */

/**
 * Remove border when inside `a` element in IE 8/9/10.
 */
img {
  border: 0;
}

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden;
}



/* ————————————————————
  IMAGE COMPONENT
——————————————————————— */

/* ————————————————————
  .image
——————————————————————— */
.image {
  overflow: hidden; // cropping larger images
  position: relative;
  display: block;
  z-index: 1;
  margin: 0;

  > img {
    position: relative;
    z-index: 2;
    display: block;
    width: 101%;
    min-width: 100%;
    height: auto;
    -webkit-backface-visibility: hidden;
    opacity: 1;
    transition: opacity 0.8s ease-out;
  }
}


/* ————————————————————
  .image.-lazy > img
——————————————————————— */
.image.-lazy > img {
  opacity: 0;

  /* fallback no js */
  .no-js & {
    opacity: 1;
  }
}



/* ————————————————————
  .image.has-placeholder
——————————————————————— */
.image.has-placeholder > img {
  position: absolute;
  top: 0;
  left: 0;
}



/* ————————————————————
  .image.-cover
——————————————————————— */
.image.-cover {
  > img {
    .objectfit & {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      object-fit: cover;
      z-index: 2;
    }

    /* fallback no objectfit */
    .no-objectfit & {
      opacity: 0 !important;
    }

    /* fallback no js & no objectfit */
    .no-js.no-objectfit & {
      opacity: 1 !important;
    }
  }


  /* FALLBACK OBJECTFIT
    js add image to background of component image
    ————————————————— */
  .no-objectfit & {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
}



/* ————————————————————
  .image__placeholder
——————————————————————— */
.image__placeholder {
  display: block;
  width: 100%;
  height: auto;
  background-size: auto 100%;
  z-index: 55;
  opacity: 1;


  /* MODIFIER
    ————————————————— */
  .-cover & {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 1;
    padding: 0 !important;
  }

  .no-objectfit .-cover.-lazy & {
    opacity: 1;
  }

  .no-objectfit .-cover & {
    opacity: 0;
  }
  /* fallback */
  .no-js & {
    opacity: 0 !important;
  }
}



/* ————————————————————
  .image__caption
——————————————————————— */
/*
.image__caption{
  font-size: ms(-1);
  margin-top: vr(.5);
}
*/
