
/* ————————————————————
  .tab
——————————————————————— */
.tab {
  position: relative;
  overflow: hidden;
  margin-top: 0;

  + .tab {
    border-top: 1px solid $noir-10;
  }
}


/* ————————————————————
  .tab__input
——————————————————————— */
.tab__input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}


/* ————————————————————
  .tab__label
——————————————————————— */
.tab__label {
  position: relative;
  display: block;
  cursor: pointer;
  font-size: ms(1) !important;
  line-height: vr(3);

  &::after {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: vr(3);
    line-height: vr(3);
    text-align: center;
    transition: all 0.35s;

    .tab input[type=checkbox] + & {
      content: "+";
    }

    .tab input[type=checkbox]:checked + & {
      transform: rotate(135deg);
    }
  }
}



/* ————————————————————
  .tab__content
——————————————————————— */
.tab__content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.35s, opacity 0.3s ease;
  margin-top: 0;
  opacity: 0;

  p {
    margin: 0 0 vr(1) 0;
  }

  .tab input:checked ~ & {
    max-height: 30em;
    opacity: 1;
  }
}

