/* ————————————————————
  .l_header
——————————————————————— */
.l_header {
  text-align: center;
  background-color: $noir;
  color: #fff;
  margin: 0;
  position: relative;
  z-index: 1;
}




/* ————————————————————
  .l_header__container
—————————————————————— */
.l_header__container {
  padding-top: vr(12);
  padding-bottom: vr(3);

  .-imagecover & {
    position: initial;
  }

  /* MODIFIER
  ————————————————— */
  .-home & {
    padding-top: vr(5);
    padding-bottom: vr(6);
    /* MEDIA QUERIES
    ————————————————— */
    @include breakpoint($breakpoint-tablet) {
      padding-top: vr(10);
      padding-bottom: vr(9);
    }
  }
  @include breakpoint($breakpoint-tablet-large) {
    padding-top: vr(9);
  }
}

.l_header__sup,
.l_header__title,
.l_header__anchornav {
  position: relative;
  z-index: 2;
  @extend %transition-fadeinup;
}



/* ————————————————————
  .l_header__sup
——————————————————————— */
.l_header__sup {
  color: $text-light;
  font-size: ms(-1);
}

/* ————————————————————
  .l_header__title
——————————————————————— */
.l_header__title {
  font-size: ms(3);
  line-height: vr(2);
  font-family: tabacG1;
  font-weight: 700;
  padding: 0 col(1, 7);
  @include dontBreakWords;

  .-home & {
    //padding: 0;
    font-size: ms(2);
    line-height: vr(1.5);
  }

  /* MEDIA QUERIES
  ————————————————— */
  @include breakpoint($breakpoint-tablet-large) {
    font-size: ms(4);
    line-height: vr(2);
  }
  @include breakpoint($breakpoint-desktop) {
    padding: 0 col(1, 7);
    font-size: ms(5);
    line-height: vr(2.5);


    /* MODIFIER
    ————————————————— */
    .-home & {
      //padding: 0;
      font-size: ms(4);
      line-height: vr(2);
    }
  }
}


/* ————————————————————
  .l_header__anchornav
——————————————————————— */
.l_header__anchornav {
  font-size: ms(-2);
  color: $text-light;
  text-transform: uppercase;
  letter-spacing: 0.15em;
}


/* ————————————————————
  .l_header__anchornav__item
——————————————————————— */
.l_header__anchornav__item {
  margin-left: vr(1);
}






/* ————————————————————
  .l_header__image
——————————————————————— */
.l_header__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  height: 100%;
  z-index: 1;
  opacity: 0.5;

  .-imagecover & {
    width: 100%;
    opacity: 0.3;
  }


  /* MEDIA QUERIES
  ————————————————— */
  @include breakpoint($breakpoint-tablet) {
    width: 50%;
  }

  // &.-cover {
  //   @include cover();
  // }
}




/* ————————————————————
  .l_header__scroll-helper
——————————————————————— */
.l_header__scroll-helper {
  text-transform: uppercase;
  font-size: ms(-2);
  letter-spacing: 2px;
  opacity: 0.4;
  display: none;
  align-items: center;
  position: absolute;
  bottom: -65px;
  left: col(1, 7);
  transform: rotate(-90deg);
  transition: opacity 0.3s ease-out;
  transform-origin: bottom left;
  z-index: 4;
  user-select: none;

  &::before {
    content: '';
    display: inline-block;
    width: 100px;
    height: 1px;
    background-color: $noir-10;
    margin-right: vr(0.5);
    margin-top: -2px;
    vertical-align: middle;
  }
  @include breakpoint($breakpoint-tablet) {
    display: inline-block;
  }


  /* HOVER
  ————————————————— */
  // .no-touchevents &:hover {
  //   text-decoration: none;
  //   opacity: 1;
  // }
}