
/* ————————————————————
  .socials
——————————————————————— */
.socials {
  padding: vr(3) 0 vr(2) 0;

  &::before {
    content: '';
    display: block;
    width: 400%;
    height: 1px;
    background-color: $noir-5;
    position: absolute;
    top: 0;
    left: -200%;
  }
}








/* ————————————————————
  .socials__links
——————————————————————— */
.socials__links {
  font-size: ms(-1);
  text-transform: uppercase;
  color: $text-light;

  li {
    margin-right: vr(1) !important;
  }


  /* MEDIA QUERIES
  ————————————————— */
  @include breakpoint($breakpoint-tablet) {
    text-align: right;

    li {
      margin-left: vr(0.5);
      margin-right: 0 !important;
    }
  }
}



/* ————————————————————
  .social-grid
——————————————————————— */
.social-grid {
  display: flex;
  flex-wrap: wrap;
  @include breakpoint($breakpoint-tablet) {
    margin-top: vr(2);
  }
}


/* ————————————————————
  .social-grid__item
——————————————————————— */
.social-grid__item {
  position: relative;

  /* MEDIA QUERIES
  ————————————————— */
  // @include breakpoint($breakpoint-desktop) {
  //   width: col(1, 4);
  //   margin-top: 0;
  // }
}



/* ————————————————————
  .social-grid__item__image
——————————————————————— */
.social-grid__item__image {
  display: block;
  width: 100%;
}


/* ————————————————————
  .social-grid__item__date
——————————————————————— */
.social-grid__item__date {
  display: block;
  color: $text_light;
  @extend .p.-small;
  margin-top: vr(0.25);
}


/* ————————————————————
  .social-grid__item__link
——————————————————————— */
.social-grid__item__link {
  @include cover();
  z-index: 2;
}