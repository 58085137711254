/* ————————————————————
  .grid-push
——————————————————————— */
.grid-push {
  margin-top: vr(-2);

  /* MEDIA QUERIES
  ————————————————— */
  @include breakpoint($breakpoint-tablet) {
    margin-top: vr(-4);

    &.-rebound {
      margin-top: vr(2);
      padding-top: vr(2);
      padding-bottom: vr(1);
      margin-bottom: 0;

      + .l_footer {
        margin-top: 0;
      }
    }
  }
}



/* ————————————————————
  .grid-push__item
——————————————————————— */
.grid-push__item {
  position: relative;
  background-color: $noir;
  overflow: hidden;


  /* HOVER
  ————————————————— */
  .no-touchevents &:hover {
    .grid-push__image {
      opacity: 0.2;
      transform: scale(1.01);
    }
  }
}


/* ————————————————————
  .grid-push__image
——————————————————————— */
.grid-push__image {
  @include cover();
  z-index: 1;
  opacity: 0.6;
  transform-origin: center center;
  will-change: transform;
  transition: opacity 1.2s ease, transform 1.2s ease 0.3s;
}



/* ————————————————————
  .grid-push__content
——————————————————————— */
.grid-push__content {
  z-index: 2;
  color: #fff;
  position: relative;
  padding-left: vr(1);
  padding-right: vr(1);
  padding-top: vr(5);
  padding-bottom: vr(3);
  //   min-height: vr(15);
  //   display: block;
  //   width: 100%;
  //   padding: vr(1);
  //   border: 1px solid red;

  .grid-push__title {
    color: inherit;
    display: block;
    font-family: 'tabacG1';
    font-weight: 700;
    font-size: ms(2);

    .-rebound & {
      font-size: ms(1);
    }
  }

  .grid-push__chapo {
    color: inherit;
    opacity: 0.8;
    font-weight: 300;
    margin-top: vr(0.5);

    .-rebound & {
      margin-top: vr(0.5);
    }
  }


  //   @include breakpoint($breakpoint-tablet-large) {
  //     padding: vr(0);
  //   }


  /* MODIFIER
  ————————————————— */
  .-rebound & {
    padding-left: vr(1);
    padding-right: vr(1);
    padding-top: vr(5);
    padding-bottom: vr(3);
  }


  /* MEDIA QUERIES
  ————————————————— */
  @include breakpoint($breakpoint-tablet) {
    padding-left: vr(1);
    padding-right: vr(1);
    padding-top: vr(12);
    padding-bottom: vr(6);

    .-rebound & {
      padding-left: vr(1);
      padding-right: vr(1);
      padding-top: vr(5);
      padding-bottom: vr(3);
    }
  }
}




/* ————————————————————
   .grid-push__link
 ——————————————————————— */
.grid-push__link {
  @include cover();
  z-index: 3;

  /* HOVER
   ————————————————— */
  .no-touchevents &:hover {
    text-decoration: none;
  }
}