/* ————————————————————
  LISTS SPECIFIC RESET
——————————————————————— */
ul, ol{
  list-style: none;
  padding-left: 0;
}



/* ————————————————————
  .linklist
——————————————————————— */
%linklist-horizontale{
  li{
    display: inline-block;
    margin-right: -.27em;
  }
}

.linklist{

  &.-horizontale{
    @extend %linklist-horizontale;
  }
}



/* ————————————————————
  .breadcrumb
——————————————————————— */
.breadcrumb{
  @extend %linklist-horizontale;

  .breadcrumb__item:after{
    content: ">";
    padding: 0 .5em;
  }
  .breadcrumb__item:last-child:after{
    content: none;
  }
}