/* ————————————————————
  .grid-profile
——————————————————————— */
.grid-profile {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: vr(2);
}



/* ————————————————————
  .grid-profile__item
——————————————————————— */
.grid-profile__item {
  margin: 0;
  width: 45%;
  margin-bottom: vr(2);
}



/* ————————————————————
  .grid-profile__image
——————————————————————— */
.grid-profile__image {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  margin-bottom: vr(1);
}


/* ————————————————————
  .grid-profile__name
——————————————————————— */
.grid-profile__name {
  color: $noir;
  font-family: "tabacG1";
  font-weight: 700;
}