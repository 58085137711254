/* ————————————————————
  .icon
——————————————————————— */
.icon{
  line-height: inherit;
  display: inline-block;
  height: vr(1);
  width: vr(1);
  vertical-align: middle;
  margin-top: -.2em;


  /* MODIFIER
  ————————————————— */
  &.-after{ margin-left: .5em; }
  &.-before{ margin-right: .5em; }

  &.-small{
    height: vr(.5);
    width: vr(.5);
  }
  &.-large{
    height: vr(1.5);
    width: vr(1.5);
  }

  /* project specific theme
  ————————————————— */
  stroke: none;
  fill: currentColor;

}



/*
.icon loading
*/

/*.icon.-loading{
  stroke-width: 1px;
  stroke: #fff;
  fill: none !important;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 37.52;
  stroke-dashoffset: 0px;
  opacity:0;
  animation-fill-mode: forwards;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-name: izyconnect-animation-loading;
  animation-duration: 1.5s;
  animation-timing-function: $easeOutQuad;
}



@keyframes izyconnect-animation-loading {
  0%{
    stroke-dashoffset: 37.52px;
    opacity:0;
  }

  50%{
    opacity:1;
    stroke-dashoffset: 0px;
  }

  100% {
    opacity:0;
    stroke-dashoffset: -37.52px;
  }
}*/
