html.show-mobile-nav {
  overflow-y: hidden !important;
}


/* ————————————————————
  .nav-mobile-wrapper
——————————————————————— */
.nav-mobile-wrapper {
  @include z-index('mobile-nav');
  @include cover();
  position: fixed;
  background-color: transparent;
  pointer-events: none;

  &::before {
    content: '';
    @include cover();
    background-color: $noir;
    opacity: 0;
    transition: opacity 0.15s ease 0.1s;
  }

  .show-mobile-nav & {
    pointer-events: auto;

    &::before {
      transition: opacity 0.3s ease;
      opacity: 0.9;
    }
  }
}


/* ————————————————————
  .nav-mobile
——————————————————————— */
.nav-mobile {
  z-index: 2;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  overflow: scroll;
  background-color: $noir;
  color: #fff;
  padding: vr(1.5);
  opacity: 0;
  width: 90%;
  transition: transform 0.2s ease-out, opacity 0.15s ease-out;
  transform: translate3d(10%, 0, 0);

  .show-mobile-nav & {
    transition: transform 0.3s ease-out 0.3s, opacity 0.3s ease-out 0.3s;
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  /* MEDIA QUERIES
   ————————————————— */
  @include breakpoint($breakpoint-tablet-large) {
    padding: vr(2.5);
    width: 45%;
  }
}


/* ————————————————————
  .nav-mobile__linklist
——————————————————————— */
.nav-mobile__linklist {
  font-size: ms(3);
  line-height: vr(1.8);
  margin-top: vr(4);


  /* MEDIA QUERIES
  ————————————————— */
  @include breakpoint($breakpoint-tablet-large) {
    font-size: ms(2);
    line-height: vr(1.4);
  }
}


/* ————————————————————
  .nav-mobile__linklist__item
——————————————————————— */
.nav-mobile__linklist__item {
  margin-bottom: vr(0.5);
}

/* ————————————————————
  .nav-mobile__contacts
——————————————————————— */
.nav-mobile__contacts {
  margin-top: vr(2);
  color: $text-light;
}


/* ————————————————————
  .nav-mobile__btn
——————————————————————— */
.nav-mobile__btn {
  position: absolute;
  top: vr(1.5);
  right: vr(1);
  margin: 0;
  opacity: 0.4;
  transition: opacity 0.3s ease;


  /* HOVER
  ————————————————— */
  .no-touchevents &:hover {
    opacity: 1;
  }
}


/* ————————————————————
  .nav-mobile__btn-large
——————————————————————— */
.nav-mobile__btn-large {
  display: block;
  @include cover();
  background-color: red;
  border: 3px solid green;
  width: 80%;
  z-index: 1;
  opacity: 0;
}